import React from 'react';
import './index.css';

const Dashboard = () => (
    <div className="app-wrapper dashboard-container">
        <div id="logo1" />
        <div className="logo_container">
            <img id="logo2" alt="logo2" src="assets/images/warner_logo.png"/>
            <img id="logo3" alt="logo3" src="assets/images/discovery_logo.png"/>
        </div>
    </div>
);

export default Dashboard;
