import JwtAuthService from './JwtAuthService';

export default class FetchService {

    static get(url, params=null, type='json') {

        const jwtAuthService = new JwtAuthService();

        const authToken = jwtAuthService.getToken();

        const objRequest = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${authToken}`,
            },
            credentials: 'include',
        }

        var apiUrl = new URL( process.env.API_URL + url);

        if(params != null) {
            Object.keys(params).forEach(key => apiUrl.searchParams.append(key, params[key]))
        }

        return fetch(apiUrl, objRequest)
            .then(async (response) => {

                if (!(response.status >= 200 && response.status < 300)) { // Status error
                    const error = {status: response.status, description: 'Unauthorized'};
                    throw error;
                }

                jwtAuthService.refreshToken(response);

                if(type === 'json') {
                    return await response.json();
                }else if(type === 'blob') {
                    return await response.blob();
                }else{
                    return await response.json();
                }

            }).catch((error) => {
                //console.error(error);
                const errorThrow = {status: error.status, description: 'An error has occurred'};
                throw errorThrow;
            });

    }

    static post(url, params=null) {

        const jwtAuthService = new JwtAuthService();

        const authToken = jwtAuthService.getToken();

        const objRequest = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${authToken}`,
            },
            credentials: 'include',
        }

        var apiUrl = new URL( process.env.API_URL + url);

        if(params != null) {
            Object.keys(params).forEach(key => apiUrl.searchParams.append(key, params[key]))
        }

        return fetch(apiUrl, objRequest)
            .then(response => {
                if (!(response.status >= 200 && response.status < 300)) { // Status error
                    const error = {status: response.status, description: 'Unauthorized'};
                    throw error;
                }

                jwtAuthService.refreshToken(response);
                return response;

            }).catch((error) => {
                //console.error(error);
                const errorThrow = {status: error.status, description: 'An error has occurred'};
                throw errorThrow;
            });
    }

}