import React, { useState } from 'react';
import { Button, Modal, TextField, Switch,FormControlLabel} from "@material-ui/core";
import FetchService from '../../../services/FetchService';
import NotificationWrapper from "util/NotificationWrapper";
import IntlMessages from 'util/IntlMessages';
import Nprogress from "nprogress";

const ModalNewUser = ({ isOpen, onClose, callUser }) => {
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [password, setPassword] = useState('');
  const [passwordCheck, setPasswordCheck] = useState('');
  const [username, setUsername] = useState('');
  const [eMail, setEmail] = useState('');
  const [agenteCod, setAgenteCod] = useState('');
  const [flagDoppioProfilo, setFlagDoppioProfilo] = useState(false);
  const [flagAdmin, setFlagAdmin] = useState(false);

  const submitIns = (event) => {
  
    try{
      let AgentCods = '';

      if(agenteCod){
          AgentCods = agenteCod.split(',');
          
          for (let index = 0; index < AgentCods.length; index++) {
              
              let element = AgentCods[index].trim();

              if(!element || element.length > 5 || element.includes(' ')){
                  throw new Error('Agent Cod. is not in compliance')
              }

              AgentCods[index] = element;
          }
          AgentCods = AgentCods.join(', ');
      }
      
      const params = {
        pUserName: username,
        pNome: name,
        pCognome: surname,
        pPassword: password,
        pEMailAddress: eMail,
        pAgenteCod: AgentCods ? AgentCods : '',
        pFlagDoppioProfilo: flagDoppioProfilo ? 'Y' : 'N',
        pFlagAdmin: flagAdmin ? 'Y' : 'N',
      };

      FetchService.post('/users/user-ins', params)
        .then((response) => {
          if (!response.ok) {
            throw new Error('Errore nella richiesta');
          }
          return response.json();
        })
        .then((data) => {
          // Use the JSON data here
          if (data <= 0) {
            let errorMessage = '';
            switch (data) {
              case 0:
              case -1:
                errorMessage = <IntlMessages id="error.global.generic" />;
                break;
              case -2:
                errorMessage = <IntlMessages id="error.global.no-privileges" />;
                break;
              case -3:
                errorMessage = <IntlMessages id="error-user-ins-3" />;
                break;
              case -4:
                errorMessage = <IntlMessages id="error-user-ins-4" />;
                break;
              case -5:
                errorMessage = <IntlMessages id="error-user-ins-5" />;
                break;
              case -6:
                errorMessage = <IntlMessages id="error-user-ins-6" />;
                break;
              case -7:
                errorMessage = <IntlMessages id="error-user-ins-7" />;
                break;
              default:
                errorMessage = <IntlMessages id="error.global.generic" />;
                break;
            }

            NotificationWrapper.error(errorMessage);
          } else {
            NotificationWrapper.success(<IntlMessages id="signUp.success" />);
            handleCloseModal();
          }
          Nprogress.done();
        })
        .catch((e) => {
          if(e.message === "Agent Cod. is not in compliance"){
            NotificationWrapper.error(e.message);
          }else{
            NotificationWrapper.error(<IntlMessages id="users.saveUser.error"/>);
          }
        });
      }catch(e){
        if(e.message === "Agent Cod. is not in compliance"){
          NotificationWrapper.error(e.message);
        }else{
          NotificationWrapper.error(<IntlMessages id="users.saveUser.error"/>);
        }
      }
  };

      
  const handleSubmit = () => {
    if (password === passwordCheck) {
      submitIns();
      callUser();
      Nprogress.done()
    } else {
      NotificationWrapper.error('Password does not match');
    }
  };

  const handleCloseModal = () => {
    setName('');
    setSurname('');
    setPassword('');
    setPasswordCheck('');
    setUsername('');
    setEmail('');
    setAgenteCod('');
    setFlagDoppioProfilo(false);
    setFlagAdmin(false);
    onClose();
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
    <div className="modal-container">
      <div className="modal-content">
         <h1>New user</h1>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-12 col-flex">
            <TextField
              label="Username"
              name="username"
              value={username}
              onChange={(event) => setUsername(event.target.value)}
              style={{ flexGrow: 1 }}
            />
          </div>

          <div className="col-lg-6 col-md-6 col-12 col-flex">
            <TextField
              label="E-mail address"
              value={eMail}
              onChange={(event) => setEmail(event.target.value)}
              style={{ flexGrow: 1 }}
            />
          </div>


          <div className="col-lg-6 col-md-6 col-12 col-flex">
            <TextField
              label="Password"
              name="Password"
              type="password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              style={{ flexGrow: 1 }}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-12 col-flex">
            <TextField
              label="Repeat Password"
              name="RepeatPassword"
              type="password"
              value={passwordCheck}
              onChange={(event) => setPasswordCheck(event.target.value)}
              style={{ flexGrow: 1 }}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6 col-md-6 col-12 col-flex">
            <TextField
              label="Name"
              name="name"
              value={name}
              onChange={(event) => setName(event.target.value)}
              style={{ flexGrow: 1 }}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-12 col-flex">
            <TextField
              label="Surname"
              name="cognome"
              value={surname}
              onChange={(event) => setSurname(event.target.value)}
              style={{ flexGrow: 1 }}
            />
          </div>
        <div className="col-lg-6 col-md-6 col-12 col-flex">
            <TextField
              label="Agent. Cod"
              value={agenteCod}
              onChange={(event) => setAgenteCod(event.target.value)}
              style={{ flexGrow: 1 }}
            />
          </div>
        </div>

        <div className="row" style={{ marginTop: 10 }}>
          <div className="col-lg-6 col-6 col-flex">
            <FormControlLabel
              control={
                <Switch
                  name="flagDoppioProfilo"
                  checked={flagDoppioProfilo}
                  onChange={(event) => setFlagDoppioProfilo(event.target.checked) }
                  color="primary"
                  style={{ flexGrow: 1 }}
                />
              }
              label="Dual Profile"
            />
          </div>

        </div>

        <div className="row">
          <div className="col-lg-6 col-6 col-flex">
            <FormControlLabel
              control={
                <Switch
                  name="flagAdmin"
                  checked={flagAdmin}
                  onChange={(event) => setFlagAdmin(event.target.checked) }
                  color="primary"
                />
              }
              label="Administrator"
            />
          </div>
        </div>



        <div className='d-flex mt-1'>
        <Button className="mr-2 bg-primary shadow mt-3" onClick={handleSubmit}>
          Save
        </Button>
        <Button className='shadow mt-3'  onClick={onClose}>
          Cancel
        </Button>

        </div>
      
      </div>
    </div>
    </Modal>
  );
};

export default ModalNewUser;
