import React from 'react';

const Footer = () => {
        return (
            <footer className="app-footer">
                <div className="d-flex flex-row justify-content-between">
                    <div>
                        <span>Developed by <a className="link-systrategy" href="https://www.systrategy.it" target="_blank">Systrategy</a></span>
                    </div>
                </div>
            </footer>
        );
    }
;

export default Footer;
