export default class JwtAuthService {

    constructor() {
        this.domain = process.env.API_URL // API server domain
        this.fetch = this.fetch.bind(this) // React binding stuff
        this.login = this.login.bind(this)
        //this.getProfile = this.getProfile.bind(this)
    }

    login(username, password, recaptchaValue) {
        // Get a token from api server using the fetch api

        const objRequest = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                username,
                password,
                recaptchaValue
            })    
        }

        var apiUrl = new URL( process.env.API_URL + "/login");

        return this.fetch(apiUrl, objRequest)
            .then(res => {
                return Promise.resolve(res);
            })
    }

    fetch(url, options) {
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }

        if (this.loggedIn()) {
            headers['Authorization'] = 'Bearer ' + this.getToken()
        }

        return fetch(url, {
            headers,
            ...options
        })
            .then(this._checkStatus)
            .then(response => response.json())
    }

    _checkStatus(response) {
        // raises an error in case response status is not a success
        if (response.status >= 200 && response.status < 300) { // Success status lies between 200 to 300
            return response
        } else {
            var error = new Error("Invalid Username or Password")
            error.response = response
            throw error
        }
    }

    getToken() {
        // Retrieves the user token from localStorage
        return localStorage.getItem('user_id')
    }

    refreshToken(response) {
        if (response.headers.get('X-Access-Token') != null && response.headers.get('X-Access-Token').trim() !== '') {
            console.log('X-Access-Token -> refreshToken');
            const idToken = response.headers.get('X-Access-Token');
            localStorage.setItem('user_id', idToken);
        }
    }

    loggedIn() {
        // Checks if there is a saved token and it's still valid
        const token = this.getToken() // GEtting token from localStorage
        return !!token && !this.isTokenExpired(token) // handwaiving here
    }

    isTokenExpired(token) {
        return false;
    }

}