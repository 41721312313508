import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import asyncComponent from '../../../util/asyncComponent';

const Settings = ({match}) => (
    <div className="app-wrapper">
        <Switch>
            <Redirect exact from={`${match.url}/`} to={`${match.url}/users/user-list`}/>
            <Route path={`${match.url}/users/user-list`} component={asyncComponent(() => import('./routes/users/userList'))}/>
            <Route path={`${match.url}/user-activity/activity-list`} component={asyncComponent(() => import('./routes/userActivity'))}/>
            <Route path={`${match.url}/users/account-details/:kuser`} component={asyncComponent(() => import('./routes/users/accountDetail'))}/>
            <Route path={`${match.url}/roles/role-list`} component={asyncComponent(() => import('./routes/roles/roleList'))}/>
            <Route path={`${match.url}/profile/change-password`} component={asyncComponent(() => import('./routes/profile/changePassword'))}/>
            <Route path={`${match.url}/profile/impersonate-user`} component={asyncComponent(() => import('./routes/profile/impersonateUser'))}/>
            <Route path={`${match.url}/profile/restricted-access-mode`} component={asyncComponent(() => import('./routes/profile/restrictedAccessMode'))}/>

            <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>
        </Switch>
    </div>
);

export default Settings;
