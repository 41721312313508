import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import asyncComponent from '../../../util/asyncComponent';

const Tracking = ({ match }) => {
    
    return (
        <div className="app-wrapper">
            <Switch>
                {/* Reindirizzamento dalla radice "tracker" a brandsolutions */}
                <Route
                    exact
                    path={`${match.url}`}
                    render={() => <Redirect to={`${match.url.replace(/\/$/, '')}/brandsolutions`} />}
                />
                <Route
                    exact
                    path={`${match.url}/`}
                    render={() => <Redirect to={`${match.url.replace(/\/$/, '')}/brandsolutions`} />}
                />
                
                {/* Rotta per brandsolutions */}
                <Route
                    path={`${match.url}/brandsolutions`}
                    component={asyncComponent(() => import('./routes/brandsolutions'))}
                />
                
                {/* Rotta per pillar */}
                <Route
                    path={`${match.url}/pillar`}
                    component={asyncComponent(() => import('./routes/brandsolutions'))}
                />
                
                {/* Rotta di fallback per 404 */}
                <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))} />
            </Switch>
        </div>
    );
};

export default Tracking;
