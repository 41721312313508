import React, { useState, useEffect } from 'react';
import {
    DataGridPro,
    LicenseInfo,
    GridOverlay
} from '@mui/x-data-grid-pro';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import FetchService from '../../../../services/FetchService';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SaveIcon from '@mui/icons-material/Save';
import TextField from '@mui/material/TextField';
import Spinner from 'components/Spinner';
import EditIcon from '@mui/icons-material/Edit';
import Pagination from '@material-ui/lab/Pagination';
import ModalAutocomplete from './ModalAutocomplete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Draggable from 'react-draggable';
import Autocomplete from '@mui/material/Autocomplete';
import utils from '../../../../../src/util/CommonUtilities'

import './mapping.css'

const PAGE_SIZE = 50;

export default function Mapping() {

    const [isLoading, setIsLoading] = useState(false);
    const [isAdvertiser, setIsAdvertiser] = useState(true);
    const [isTargets, setIsTargets] = useState(false);
    const [isChannels, setIsChannels] = useState(false);
    const [isDiscovery, setIsDiscovery] = useState(true);
    const [isArianna, setIsArianna] = useState(false);
    const [isNielsen, setIsNielsen] = useState(false);
    const [tabValue, setTabValue] = useState(0);
    const [page, setPage] = useState(1);
    const [rowCount, setRowCount] = useState(0);

    const pagerCount = Math.floor(rowCount / PAGE_SIZE) + 1;

    const [newRecordset, setRecordset] = useState([]);
    const [recordSetAdv, setRecordsetAdv] = useState([]);
    const [recordSetAutocomplete, setRecordsetAutocomplete] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);

    const options = [
      'Option 1',
      'Option 2',
      'Option 3',
      'Option 4',
      // Aggiungi altre opzioni secondo le tue esigenze
    ];

    
    const [openModal, setOpenModal] = useState(false);
    const [openModalDelete, setOpenModalDelete] = useState(false);

    const handleOpenModal = () => {
        setOpenModal(true);
      
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };
    
    const buttons = document.querySelectorAll('.button');

    const handlePagerChange = (event, value) => {
        setPage(value);
      };

    buttons.forEach(button => {
        button.addEventListener('click', () => {
            buttons.forEach(btn => {
                btn.classList.remove('selected');
            });

            button.classList.add('selected');
        });
    });

    const buttonsSwitch = async () => {
        const buttonDiscovery = document.querySelector('#buttDiscovery');
        const buttonArianna = document.querySelector('#buttArianna');
        const buttonNielsen = document.querySelector('#buttNielsen');

        buttonDiscovery && buttonDiscovery.classList.remove('selected');
        buttonArianna && buttonArianna.classList.remove('selected');
        buttonNielsen && buttonNielsen.classList.remove('selected');

        if (tabValue === 0 || tabValue === 2) {
            buttonDiscovery.classList.add('selected');
        } else if (tabValue === 1) {
            buttonNielsen.classList.add('selected');
        }
    }

    useEffect(() => {
        if(tabValue === 0 && isDiscovery) {
            getAdvDiscovery();
        }
    }, [page])


    useEffect(() => {
        buttonsSwitch()
    }, [tabValue])


    function a11yProps(index) { return { id: `simple-tab-${index}`,  'aria-controls': `simple-tabpanel-${index}`, };  }
    const handleChangeTab = (event, newValue) => {
        setTabValue(newValue);
    };


    // CHIAMATE 

    // CHANNELS - - - - - - - -  DISCOVERY
    const getClientiDiscovery = async () => {
        setIsLoading(true);
        try {
            const recordset = await FetchService.get("/alverman/get-map-canali-discovery", {});
            setRecordset(recordset);
            setIsLoading(false);
        } catch (error) {
            console.error(error);
        }
    };


    //   CHANNELS - - - - - - - -  ARIANNA
    const getClientiArianna = async () => {
        setIsLoading(true);
        try {
            const recordset = await FetchService.get("/alverman/get-map-canali-arianna", {});
            setRecordset(recordset);
            setIsLoading(false);
        } catch (error) {
            console.error(error);
        }
    };



    // TARGETS - - - - - - - -  ARIANNA   
    const getTargetArianna = async () => {
        setIsLoading(true);
        try {
            const recordset = await FetchService.get("/alverman/get-target-arianna", {});
            setRecordset(recordset);
            setIsLoading(false);
        } catch (error) {
            console.error(error);
        }
    };

    //TARGETS - - - - - - - -  NIELSEN
    const getTargetNielsen = async () => {
        setIsLoading(true);
        try {
            const recordset = await FetchService.get("/alverman/get-target-nielsen", {});
            setRecordset(recordset);

            setIsLoading(false);
        } catch (error) {
            console.error(error);
        }
    };


    
    // ADVERTISERS - - - - - - - - DISCOVERY

    const getAdvDiscovery = async () => {
        setIsLoading(true);
        const numRecords = PAGE_SIZE; 
        const firstRecord = (page - 1) * numRecords + 1;
        
        const params = {
            firstRecord,
            numRecords,
        };
        try {
            const recordset = await FetchService.get("/alverman/get-map-clienti-discovery", params);
            setRecordsetAdv(recordset);
            recordset.length > 0 && setRowCount(recordset[0].NUM_TOT);
            setIsLoading(false);
        } catch (error) {
            console.error(error);
        }
    };


    // ADVERTISERS - - - - - - - - NIELSEN

    const getAdvNielsen = async () => {
        setIsLoading(true);
        try {
            const recordset = await FetchService.get("/alverman/get-map-clienti-nielsen", {});
            setRecordsetAdv(recordset);
            setIsLoading(false);
        } catch (error) {
            console.error(error);
        }
    };


    // ADVERTISERS - - - - - - - - ARIANNA

    const getAdvArianna = async () => {
        setIsLoading(true);
        try {
            const recordset = await FetchService.get("/alverman/get-map-clienti-arianna", {});
            setRecordsetAdv(recordset);
            setIsLoading(false);

        } catch (error) {
            console.error(error);
        }
    };

    // autocomplete
    const getClientiAutocomplete = async () => {
        setIsLoading(true);
        try {
            const recordset = await FetchService.get("/alverman/get-clienti-autocomplete", {});
            setRecordsetAutocomplete(recordset);
            setIsLoading(false);

        } catch (error) {
            console.error(error);
        }
    };


 

    //   BOTTONI CHANNELS
    const arianna = async () => {
        setIsDiscovery(false);
        setIsArianna(true);
        isChannels && getClientiArianna();
        isTargets && getTargetArianna();
        isAdvertiser && getAdvArianna();

        
    }
    const discovery = async () => {
        setIsDiscovery(true);
        setIsArianna(false);
        isChannels && getClientiDiscovery();
        isAdvertiser && getAdvDiscovery();
    }


    const nielsen = async () => {
        setIsDiscovery(false);
        setIsArianna(false);
        setIsNielsen(true);
        isAdvertiser && getAdvNielsen();
        isTargets && getTargetNielsen();
    }


    //   3 TAB 
    const targets = async () => {
        setIsChannels(false);
        setIsAdvertiser(false);
        setIsTargets(true);
        getTargetNielsen();


    }

    const advertisers = async () => {
        setIsChannels(false);
        setIsAdvertiser(true);
        setIsTargets(false);

        getAdvDiscovery();

    }

    const channels = async () => {
        setIsChannels(true);
        setIsAdvertiser(false);
        setIsTargets(false);

        getClientiDiscovery();
    }
    const displayTableDiscovery = (recordSetAdv) => {
        const aHtml = [];
        let prevRecord = '';
        for (let i = 0; i < recordSetAdv.length; i++) {
            const record = recordSetAdv[i];

            aHtml.push(
                <TableRow
                    key={record.PROG_RECORD} className={record.ROWSPAN ? 'diffColor ' : ''}>
                    <TableCell > {record.CLIENTE_DISCOVERY_DESC_MODIFIED} </TableCell>
                    <TableCell>{record.PRODOTTO_DISCOVERY_DESC}</TableCell>
                    <TableCell className='mt-1 p-1'>
                       

                        <EditIcon onClick={(event) => handleOpenModal()} className='mx-2' />
                    
                     

                    </TableCell>
                </TableRow>
            );



                   
            prevRecord = { ...record };



            const diffColorToMakePari = document.querySelectorAll('.diffColor');

            for (let i = 0; i < diffColorToMakePari.length; i++) {
                if (i % 2 === 0) {
                    diffColorToMakePari[i].classList.add('diffColorPari');
                } else {
                    diffColorToMakePari[i].classList.add('diffColorDefinitive');
                }
            }
            const rows = document.querySelectorAll('tr');

            // Primo ciclo per trovare le righe con la classe "diffColorPari"
            for (let i = 0; i < rows.length; i++) {
                const currentRow = rows[i];
                if (currentRow.classList.contains('diffColorPari')) {
                    // Secondo ciclo per aggiungere la classe "diffColorCHILD" alle righe successive senza "diffColorDefinitive"
                    for (let j = i + 1; j < rows.length; j++) {
                        const nextRow = rows[j];
                        if (!nextRow.classList.contains('diffColorDefinitive')) {
                            nextRow.classList.add('diffColorCHILD');
                        } else {
                            break; // Interrompi il secondo ciclo quando trovi una riga con "diffColorDefinitive"
                        }
                    }
                }
            }

        }

        return aHtml;
    };

    useEffect(() => {
        getAdvDiscovery();
    }, [])


    useEffect(() => {
         if (selectedOption && selectedOption.length ) {
            utils.debounce( getClientiAutocomplete , 1000 )();
         }
    }, [ selectedOption ]);

    function PaperComponent(props) {
        return (
          <Draggable
            handle="#draggable-dialog-campaign-dett"
            cancel={'[class*="MuiDialogContent-root"]'}
          >
            <Paper {...props} />
          </Draggable>
        );
      }

    return (
        <div className='MappingAlverman'>
            <Spinner visible={isLoading} />
                          
       
             
            <div id="dataGrid" className={`usersDataGrid MuiDataGrid-root `} style={{ width: '100%' }}>
                <Dialog open={openModal} onClose={handleCloseModal} PaperComponent={PaperComponent}>
                    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-campaign-dett">
                        Mapping
                    </DialogTitle>
                    <DialogContent>
                        <Autocomplete
                            value={selectedOption}
                            onChange={(event, newValue) => {
                                setSelectedOption(newValue);
                            }}
                            options={recordSetAutocomplete}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                />
                            )}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={handleCloseModal}>Cancel</Button>
                        <Button variant="contained" style={{ backgroundColor: "#2789d8", color: "white" }} onClick={handleCloseModal}>Insert</Button>
                    </DialogActions>
                </Dialog>
                <div className="header">
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={tabValue} onChange={handleChangeTab} aria-label="basic tabs example">
                            <Tab onClick={advertisers} label="Advertisers" {...a11yProps(0)} />
                            <Tab onClick={targets} label="Targets" {...a11yProps(2)} />
                            <Tab onClick={channels} label="Channels" {...a11yProps(3)} />
                        </Tabs>
                    </Box>


                    <ButtonGroup variant="contained" aria-label="outlined primary button group" className='Buttons'>
                        {!isTargets && <Button id="buttDiscovery" className="button" onClick={discovery}> Discovery</Button>}
                        {!isChannels && <Button id="buttNielsen" className="button" onClick={nielsen}> Nielsen</Button>}
                        <Button id="buttArianna" className="button" onClick={arianna}> Arianna</Button>
                    </ButtonGroup>
                </div>


                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        {!isAdvertiser && (
                            <TableHead>
                                <TableRow>
                                    <TableCell>Channel</TableCell>
                                    <TableCell>Alverman Channel</TableCell>
                                </TableRow>
                            </TableHead>)}
                        {isAdvertiser && (
                            <TableHead>
                                <TableRow>
                                    <TableCell>Advertiser</TableCell>
                                    <TableCell>Product</TableCell>
                                    <TableCell>Alverman advertiser</TableCell>
                                </TableRow>
                            </TableHead>)}

                        <TableBody>
                            {/* CHANNEL DISCOVERY  */}
                            {isChannels && isDiscovery && (
                                newRecordset.map((row) => (
                                    <TableRow key={row.CANALE_DISCOVERY_COD} >
                                        <TableCell>{row.CANALE_DISCOVERY_DESC}</TableCell>
                                        <TableCell>
                                            <Spinner visible={isLoading} />
                                            <TextField id="outlined-basic" variant="outlined" />
                                            <IconButton onClick={() => console.log('save')} aria-label="save">
                                                <SaveIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))
                            )}

                            {/* CHANNELS ARIANNA */}
                            {isChannels && isArianna && (
                                newRecordset.map((row) => (
                                    <TableRow key={row.CANALE_ARIANNA_COD}>
                                        <TableCell>{row.CANALE_ARIANNA_DESC}</TableCell>
                                        <TableCell>
                                            <Spinner visible={isLoading} />
                                            <TextField id="outlined-basic" variant="outlined" />
                                            <IconButton onClick={() => console.log('save')} aria-label="save">
                                                <SaveIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                )
                                ))}

                            {/* TARGETS NIELSEN */}
                            {isTargets && isNielsen && (
                                newRecordset.map((row) => (
                                    <TableRow key={row.TARGET_NIELSEN_COD}>
                                        <TableCell>{row.TARGET_NIELSEN_DESC}</TableCell>
                                        <TableCell>
                                            <Spinner visible={isLoading} />
                                            <TextField id="outlined-basic" variant="outlined" />
                                            <IconButton onClick={() => console.log('save')} aria-label="save">
                                                <SaveIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                )
                                ))}

                            {/* TARGET ARIANNA */}

                            {isTargets && isArianna && (
                                newRecordset.map((row) => (
                                    <TableRow key={row.TARGET_ARIANNA_COD}>
                                        <TableCell>{row.TARGET_ARIANNA_DESC}</TableCell>
                                        <TableCell>
                                            <Spinner visible={isLoading} />
                                            <TextField id="outlined-basic" variant="outlined" />
                                            <IconButton onClick={() => console.log('save')} aria-label="save">
                                                <SaveIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                )
                                ))}


                            {/* ADVERTISERS  */}

                            {/* ADV DISCOVERY */}

                            {isAdvertiser && isDiscovery && (
                                displayTableDiscovery(recordSetAdv)
                            )}


                            {/* ADV NIELSEN */}
                            {isAdvertiser && isNielsen && (
                                recordSetAdv.map((row) => (
                                    <TableRow key={row.PROG_RECORD} className={row.PROG_RECORD % 2 === 0 ? 'even-row' : 'odd-row'}>
                                        <TableCell>{row.CLIENTE_NIELSEN_DESC}</TableCell>
                                        <TableCell>{row.PRODOTTO_NIELSEN_DESC}</TableCell>
                                        <TableCell>
                                            <Spinner visible={isLoading} />
                                            <TextField id="outlined-basic" variant="outlined" />
                                            <IconButton onClick={() => console.log('save')} aria-label="save">
                                                <SaveIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                )
                                ))}

                            {/* ADV ARIANNA */}
                            {isAdvertiser && isArianna && (
                                recordSetAdv.map((row) => (
                                    <TableRow key={row.PROG_RECORD}>
                                        <TableCell>{row.CLIENTE_ARIANNA_DESC}</TableCell>
                                        <TableCell>{row.PRODOTTO_ARIANNA_DESC}</TableCell>
                                        <TableCell>
                                            <Spinner visible={isLoading} />
                                            <TextField id="outlined-basic" variant="outlined" />
                                            <IconButton onClick={() => console.log('save')} aria-label="save">
                                                <SaveIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                )
                                ))}
                         <div className="pager">
            { ( pagerCount > 1 && isAdvertiser ) && <Pagination count={pagerCount} page={page} onChange={handlePagerChange} /> }
            </div>
                        </TableBody>
                    </Table>
                </TableContainer>


            </div>

        </div>
    )
}
