import React, {Component} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {connect} from 'react-redux';
import {IntlProvider} from 'react-intl'
import 'react-toggle-switch/dist/css/switch.min.css';
import 'rc-drawer/assets/index.css';
import 'styles/bootstrap.scss'
import 'styles/app.scss';
import 'styles/app-rtl.scss';
import AppLocale from '../lngProvider';

import MainApp from 'app/index';
import SignIn from './SignIn';
// import ForgotCredentials from './ForgotCredentials';
import {setInitUrl} from '../actions/Auth';
import asyncComponent from 'util/asyncComponent';
import { NotificationContainer } from 'react-notifications';
import FetchService from '../services/FetchService';
import {userSignOut} from 'actions/Auth';

const RestrictedRoute = ({component: Component, authUser, ...rest}) =>
    <Route
        {...rest}
        render={props =>
            authUser
                ? <Component {...props} />
                : <Redirect
                    to={{
                        pathname: '/signin',
                        state: {from: props.location}
                    }}
                />}
    />;

class App extends Component {

    async componentDidMount() {
        //primo check (al caricamento della pagina) che il token sia ancora valido, altrimenti Logout:
        let isLoggedIn = null;
        try {
            isLoggedIn = await FetchService.get('/is-logged-in');
        }catch (error) {
            this.props.userSignOut();
            return;
        }
    }

    async componentWillMount() {

        if (this.props.initURL === '') {
            this.props.setInitUrl(this.props.history.location.pathname);
        }

    }

    render() {
        const {match, location, locale, authUser, initURL, isDirectionRTL} = this.props;
        if (location.pathname === '/') {
            if (authUser === null) {
                return ( <Redirect to={'/signin'}/> );
            } else if (initURL === '' || initURL === '/' || initURL === '/signin') {
                return ( <Redirect to={'/app/dashboard'}/> );
            } else {
                return ( <Redirect to={initURL}/> );
            }
        }

        // for RTL Support
        if (isDirectionRTL) {
            document.body.classList.add('rtl')
        } else {
            document.body.classList.remove('rtl');
        }

        const currentAppLocale = AppLocale[locale.locale];
        return (
            <IntlProvider
                locale={currentAppLocale.locale}
                messages={currentAppLocale.messages}
            >
                <div className="app-main">
                    <NotificationContainer/>

                    <Switch>
                        <RestrictedRoute path={`${match.url}app`} authUser={authUser}
                                         component={MainApp}/>
                        <Route path='/signin' component={SignIn}/>
                        <Route
                            component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>
                    </Switch>
                </div>
            </IntlProvider>
        );
    }
}

//                         <Route path='/forgot' component={ForgotCredentials}/>

const mapStateToProps = ({settings, auth}) => {
    const {locale, isDirectionRTL} = settings;
    const {authUser, initURL} = auth;
    return {locale, isDirectionRTL, authUser, initURL}
};

export default connect(mapStateToProps, {setInitUrl, userSignOut})(App);
