import React from "react";
import "./privilegeList.css";
import FetchService from 'services/FetchService';
import {Button} from "reactstrap";
import Nprogress from "nprogress";
import NotificationWrapper from "util/NotificationWrapper";
import IntlMessages from 'util/IntlMessages';
import {connect} from "react-redux";

import MaterialButton from '@material-ui/core/Button';

class privilegeList extends React.Component {

    icoPriv = [];
    icoDeny = [];
    
    aoPrivilegesMap = [
        
         { sSection: "Upload / Import"         ,sPrivField: "P_LOAD_NIELSEN_INV_GRP"    ,sPrivName: "Nielsen (AQX): Standard Investments / GRP"                   }
        ,{ sSection: "Upload / Import"         ,sPrivField: "P_LOAD_ARIANNA_GRP"        ,sPrivName: "Arianna: GRP"                                                }
        ,{ sSection: "Upload / Import"         ,sPrivField: "P_LOAD_DISCOVERY_ANAG"     ,sPrivName: "Discovery (BTS): Advertisers / Products / Agents / Holdings" }
        ,{ sSection: "Upload / Import"         ,sPrivField: "P_LOAD_DISCOVERY_INV"      ,sPrivName: "Discovery (BTS): Standard Investments / Share Investments"   }
        
        ,{ sSection: "General Privileges"      ,sPrivField: "P_ANALYSIS_TARGET"         ,sPrivName: "Analysis Target"                                             }
        ,{ sSection: "General Privileges"      ,sPrivField: "P_CENTRI_MEDIA"            ,sPrivName: "Agents Holdings management"                                  }
        ,{ sSection: "General Privileges"      ,sPrivField: "P_AGENZIE"                 ,sPrivName: "Agents management"                                           }
        ,{ sSection: "General Privileges"      ,sPrivField: "P_CLIENTI_AGENZIE"         ,sPrivName: "Forced agents management"                                    }
        ,{ sSection: "General Privileges"      ,sPrivField: "P_CANALI"                  ,sPrivName: "Channels management"                                         }
        ,{ sSection: "General Privileges"      ,sPrivField: "P_TARGET"                  ,sPrivName: "Targets management"                                          }
        ,{ sSection: "General Privileges"      ,sPrivField: "P_BUSINESS_TYPES"          ,sPrivName: "Business types management"                                   }
        ,{ sSection: "General Privileges"      ,sPrivField: "P_REPORT_LAST_DATES"       ,sPrivName: "Report last dates management"                                }
        ,{ sSection: "General Privileges"      ,sPrivField: "P_FATTORI_CONVERSIONE"     ,sPrivName: "Conversion factors management"                               }
        ,{ sSection: "General Privileges"      ,sPrivField: "P_ALERTS"                  ,sPrivName: "Alerts"                                                      }
        ,{ sSection: "General Privileges"      ,sPrivField: "P_IMPERSONATE"             ,sPrivName: "Impersonate"                                                 }
        ,{ sSection: "General Privileges"      ,sPrivField: "P_RESTRICTED_ACCESS"       ,sPrivName: "Restricted Access"                                           }
        ,{ sSection: "General Privileges"      ,sPrivField: "P_PORTAFOGLIO"             ,sPrivName: "Portfolio"                                                   }
        
        ,{ sSection: "Mapping (Alverman)"      ,sPrivField: "P_MAP_CLIENTI_NIELSEN"     ,sPrivName: "Nielsen Advertisers"                                         }
        ,{ sSection: "Mapping (Alverman)"      ,sPrivField: "P_MAP_CLIENTI_DISCOVERY"   ,sPrivName: "Discovery Advertisers"                                       }
        ,{ sSection: "Mapping (Alverman)"      ,sPrivField: "P_MAP_CLIENTI_ARIANNA"     ,sPrivName: "Arianna Advertisers"                                         }
        ,{ sSection: "Mapping (Alverman)"      ,sPrivField: "P_MAP_TARGET_NIELSEN"      ,sPrivName: "Nielsen Targets"                                             }
        ,{ sSection: "Mapping (Alverman)"      ,sPrivField: "P_MAP_TARGET_ARIANNA"      ,sPrivName: "Arianna Targets"                                             }
        ,{ sSection: "Mapping (Alverman)"      ,sPrivField: "P_MAP_CANALI_ARIANNA"      ,sPrivName: "Arianna Channels"                                            }
        ,{ sSection: "Mapping (Alverman)"      ,sPrivField: "P_MAP_CANALI_DISCOVERY"    ,sPrivName: "Discovery Channels"                                          }
        
        ,{ sSection: "Advertisers / Products"  ,sPrivField: "P_CLIENTI"                 ,sPrivName: "Advertisers management"                                      }
        ,{ sSection: "Advertisers / Products"  ,sPrivField: "P_PRODOTTI_NIELSEN"        ,sPrivName: "Nielsen Products management"                                 }
        ,{ sSection: "Advertisers / Products"  ,sPrivField: "P_PRODOTTI_ARIANNA"        ,sPrivName: "Arianna Products management"                                 }
        ,{ sSection: "Advertisers / Products"  ,sPrivField: "P_PRODOTTI_DISCOVERY"      ,sPrivName: "Discovery Products management"                               }
        
        ,{ sSection: "Reports"                 ,sPrivField: "P_RPT_DISCOVERY_ANNO_MESE" ,sPrivName: "Discovery"                                                   }
        ,{ sSection: "Reports"                 ,sPrivField: "P_RPT_COMPETITORS_ANNO"    ,sPrivName: "Competitors"                                                 }
        
        ,{ sSection: "DasMarianna"             ,sPrivField: "P_POL_COMM"                ,sPrivName: "General"                                                     }
        ,{ sSection: "DasMarianna"             ,sPrivField: "P_POL_COMM_IMPORT"         ,sPrivName: "Import"                                                      }
        
        ,{ sSection: "Roobeek"                 ,sPrivField: "P_ROOBEEK"                 ,sPrivName: "General"                                                     }
        
        ,{ sSection: "CRM Analyzer"            ,sPrivField: "P_PIPELINE_FORECAST"       ,sPrivName: "Pipeline Forecast"                                           }
        ,{ sSection: "CRM Analyzer"            ,sPrivField: "P_PIPELINE_SNAPSHOT"       ,sPrivName: "Pipeline Snapshot"                                           }
        ,{ sSection: "CRM Analyzer"            ,sPrivField: "P_TRACKER_AVAILAB"         ,sPrivName: "Tracker Brand Solutions"                                     }
        ,{ sSection: "CRM Analyzer"            ,sPrivField: "P_TRACKER_PILLAR"          ,sPrivName: "Tracker Pillar"                                              }
        ,{ sSection: "CRM Analyzer"            ,sPrivField: "P_TRACKER_PILLAR_DAILY"    ,sPrivName: "Tracker Pillar Daily"                                        }
        ,{ sSection: "CRM Analyzer"            ,sPrivField: "P_CRM_CHANNELS"            ,sPrivName: "Channels (CRM)"                                              }
        ,{ sSection: "CRM Analyzer"            ,sPrivField: "P_CRM_DELIVERIES"          ,sPrivName: "Deliveries (CRM)"                                            }
        ,{ sSection: "CRM Analyzer"            ,sPrivField: "P_CRM_KUBIK_AUDIENCES"     ,sPrivName: "Kubik Audiences (CRM)"                                       }
        ,{ sSection: "CRM Analyzer"            ,sPrivField: "P_CRM_MAPPING"             ,sPrivName: "Mapping (CRM)"                                               }
        ,{ sSection: "CRM Analyzer"            ,sPrivField: "P_OVERBOOKING"             ,sPrivName: "Overbooking"                                                 }
        ,{ sSection: "CRM Analyzer"            ,sPrivField: "P_OVERBOOKING_BACINO"      ,sPrivName: "Overbooking (advertising pool)"                              }
        ,{ sSection: "CRM Analyzer"            ,sPrivField: "P_PROVV_COMMISSIONS"       ,sPrivName: "Commission Management"                                       }
        ,{ sSection: "CRM Analyzer"            ,sPrivField: "P_PROVV_STATEMENTS"        ,sPrivName: "Commission Statements"                                       }
        ,{ sSection: "CRM Analyzer"            ,sPrivField: "P_AGENDA"                  ,sPrivName: "Agenda"                                                      }
    ];
    
    constructor(props) {
        super(props);

        this.state = {
            kUser: this.props.kUser,
            save: this.props.save,
            admin: this.props.admin,
            modified: false,
            privileges: [],
            loading: true,
            refresh: false,
        
        };

        const adminClass = this.state.admin ? 'UserIsAnAdmin' : '';

        this.icoPriv['W'] = <div className={`write alert-success ${adminClass}`} title="Write"><i className="zmdi zmdi-edit zmdi-hc-fw"></i></div>;
        this.icoPriv['R'] = <div className={`read alert-warning ${adminClass}`} title="Read"><i className="zmdi zmdi-eye zmdi-hc-fw"></i></div>;
        this.icoPriv['N'] = <div className={`denied alert-secondary ${adminClass}`} title="Denied"><i className="zmdi zmdi-block zmdi-hc-fw"></i></div>;
        
        this.icoDeny['Y'] = <div className={`denied alert-secondary ${adminClass}`} title="Denied"><i className="zmdi zmdi-block zmdi-hc-fw"></i></div>;
        this.icoDeny['N'] = <div className={`no-denied success-icon-denied ${adminClass}`} title="Allowed"><i className="zmdi zmdi-block zmdi-hc-fw"></i></div>;
        
    }

    componentDidMount() {
        this.getPrivileges();
    }

    componentDidUpdate() {
        if (this.props.save === true && this.state.modified === true) {
            this.handleSubmit();
            this.setState({modified:false})
        } 
        
    }

    componentWillReceiveProps(props) {
        const { refresh } = this.props;
        if (props.refresh !== refresh) {
            this.getPrivileges();
        }

        this.setState({admin : props.admin});
    }

    getPrivileges() {
        const params = {kUser: this.state.kUser};

        FetchService.get("/users/user-get-privileges", params)
            .then((data) => {
                this.setState({ privileges: data, loading : false});
              
            })
            .catch((error) => {
                console.log(error)
            });
    }

    changeIcoPriv(colPriv, colIndex){
        if (this.state.admin === false) {
            this.setState({modified: true})
            let privileges = this.state.privileges;

            const colValue = privileges[colIndex][colPriv];
    
            if(colIndex == 0){ //direct privs columns
                if(colValue == "W"){
                    privileges[colIndex][colPriv] = 'N';
                }else if(colValue == "R"){
                    privileges[colIndex][colPriv] = 'W';
                }else if(colValue == "N"){
                    privileges[colIndex][colPriv] = 'R';
                }
            }else if(colIndex == 2){ 
                if(colValue == "Y"){
                    privileges[colIndex][colPriv] = 'N';
                }else if(colValue == "N"){
                    privileges[colIndex][colPriv] = 'Y';
                }
            }
            
            this.setState({ privileges: privileges});
        } else {
            NotificationWrapper.error('You cannot modify admin privileges.');

        }
      
    }
    
    async handleSubmit(event) {

     
        const privileges = this.state.privileges;

        const paramsPrivs = {
            userId: this.state.kUser,
            pPrivLoadNielsenInvGRP:        privileges[0].P_LOAD_NIELSEN_INV_GRP,
            pPrivLoadNielsenInvShr:        privileges[0].P_LOAD_NIELSEN_INV_SHR,
            pPrivLoadAriannaGRP:           privileges[0].P_LOAD_ARIANNA_GRP    ,
            pPrivLoadDiscoveryAnag:        privileges[0].P_LOAD_DISCOVERY_ANAG ,
            pPrivLoadDiscoveryInv:         privileges[0].P_LOAD_DISCOVERY_INV  ,
            pPrivMapClientiNielsen:        privileges[0].P_MAP_CLIENTI_NIELSEN ,
            pPrivMapClientiDiscovery:      privileges[0].P_MAP_CLIENTI_DISCOVERY,
            pPrivMapClientiArianna:        privileges[0].P_MAP_CLIENTI_ARIANNA ,
            pPrivMapTargetNielsen:         privileges[0].P_MAP_TARGET_NIELSEN  ,
            pPrivMapTargetArianna:         privileges[0].P_MAP_TARGET_ARIANNA  ,
            pPrivMapCanaliArianna:         privileges[0].P_MAP_CANALI_ARIANNA  ,
            pPrivMapCanaliDiscovery:       privileges[0].P_MAP_CANALI_DISCOVERY,
            pPrivClienti:                  privileges[0].P_CLIENTI             ,
            pPrivAgenzie:                  privileges[0].P_AGENZIE             ,
            pPrivProdottiNielsen:          privileges[0].P_PRODOTTI_NIELSEN    ,
            pPrivProdottiArianna:          privileges[0].P_PRODOTTI_ARIANNA    ,
            pPrivProdottiDiscovery:        privileges[0].P_PRODOTTI_DISCOVERY  ,
            pPrivCentriMedia:              privileges[0].P_CENTRI_MEDIA        ,
            pPrivCanali:                   privileges[0].P_CANALI              ,
            pPrivTarget:                   privileges[0].P_TARGET              ,
            pPrivRptDiscoveryAnnoMese:     privileges[0].P_RPT_DISCOVERY_ANNO_MESE,
            pPrivRptCompetitorsAnno:       privileges[0].P_RPT_COMPETITORS_ANNO,
            pPrivBusinessTypes:            privileges[0].P_BUSINESS_TYPES      ,
            pPrivReportLastDates:          privileges[0].P_REPORT_LAST_DATES   ,
            pPrivFattoriConversione:       privileges[0].P_FATTORI_CONVERSIONE ,
            pPrivClientiAgenzie:           privileges[0].P_CLIENTI_AGENZIE     ,
            pPrivAlerts:                   privileges[0].P_ALERTS              ,
            pPrivImpersonate:              privileges[0].P_IMPERSONATE         ,
            pPrivRestrictedAccess:         privileges[0].P_RESTRICTED_ACCESS   ,
            pPrivPolComm:                  privileges[0].P_POL_COMM            ,
            pPrivPolCommImport:            privileges[0].P_POL_COMM_IMPORT     ,
            pPrivPortafoglio:              privileges[0].P_PORTAFOGLIO         ,
            pPrivRoobeek:                  privileges[0].P_ROOBEEK             ,
            pPrivCbContracts:              privileges[0].P_CB_CONTRACTS        ,
            pPrivPipelineForecast:         privileges[0].P_PIPELINE_FORECAST   ,
            pPrivPipelineSnapshot:         privileges[0].P_PIPELINE_SNAPSHOT   ,
            pPrivTrackerAvailab  :         privileges[0].P_TRACKER_AVAILAB     ,
            pPrivTrackerAvailabPillar:     privileges[0].P_TRACKER_PILLAR      ,
            pPrivTrackerPillarDaily:       privileges[0].P_TRACKER_PILLAR_DAILY,
            pPrivOverbooking     :         privileges[0].P_OVERBOOKING         ,
            pPrivOverbookingBacino:        privileges[0].P_OVERBOOKING_BACINO  ,    
            pPrivProvvCommissions:         privileges[0].P_PROVV_COMMISSIONS   ,  
            pPrivProvvStatements:          privileges[0].P_PROVV_STATEMENTS    ,  
            pPrivAgenda:                   privileges[0].P_AGENDA              ,
            pPrivAnalysisTarget:           privileges[0].P_ANALYSIS_TARGET
           ,pPrivCRMChannels:              privileges[0].P_CRM_CHANNELS
           ,pPrivCRMDeliveries:            privileges[0].P_CRM_DELIVERIES
           ,pPrivCRMKubikAudiences:        privileges[0].P_CRM_KUBIK_AUDIENCES
           ,pPrivCRMMapping:               privileges[0].P_CRM_MAPPING
        };
        const paramsDeny = {
            userId: this.state.kUser,
            pPrivLoadNielsenInvGRP:        privileges[2].P_LOAD_NIELSEN_INV_GRP,
            pPrivLoadNielsenInvShr:        privileges[2].P_LOAD_NIELSEN_INV_SHR,
            pPrivLoadAriannaGRP:           privileges[2].P_LOAD_ARIANNA_GRP    ,
            pPrivLoadDiscoveryAnag:        privileges[2].P_LOAD_DISCOVERY_ANAG ,
            pPrivLoadDiscoveryInv:         privileges[2].P_LOAD_DISCOVERY_INV  ,
            pPrivMapClientiNielsen:        privileges[2].P_MAP_CLIENTI_NIELSEN ,
            pPrivMapClientiDiscovery:      privileges[2].P_MAP_CLIENTI_DISCOVERY,
            pPrivMapClientiArianna:        privileges[2].P_MAP_CLIENTI_ARIANNA ,
            pPrivMapTargetNielsen:         privileges[2].P_MAP_TARGET_NIELSEN  ,
            pPrivMapTargetArianna:         privileges[2].P_MAP_TARGET_ARIANNA  ,
            pPrivMapCanaliArianna:         privileges[2].P_MAP_CANALI_ARIANNA  ,
            pPrivMapCanaliDiscovery:       privileges[2].P_MAP_CANALI_DISCOVERY,
            pPrivClienti:                  privileges[2].P_CLIENTI             ,
            pPrivAgenzie:                  privileges[2].P_AGENZIE             ,
            pPrivProdottiNielsen:          privileges[2].P_PRODOTTI_NIELSEN    ,
            pPrivProdottiArianna:          privileges[2].P_PRODOTTI_ARIANNA    ,
            pPrivProdottiDiscovery:        privileges[2].P_PRODOTTI_DISCOVERY  ,
            pPrivCentriMedia:              privileges[2].P_CENTRI_MEDIA        ,
            pPrivCanali:                   privileges[2].P_CANALI              ,
            pPrivTarget:                   privileges[2].P_TARGET              ,
            pPrivRptDiscoveryAnnoMese:     privileges[2].P_RPT_DISCOVERY_ANNO_MESE,
            pPrivRptCompetitorsAnno:       privileges[2].P_RPT_COMPETITORS_ANNO,
            pPrivBusinessTypes:            privileges[2].P_BUSINESS_TYPES      ,
            pPrivReportLastDates:          privileges[2].P_REPORT_LAST_DATES   ,
            pPrivFattoriConversione:       privileges[2].P_FATTORI_CONVERSIONE ,
            pPrivClientiAgenzie:           privileges[2].P_CLIENTI_AGENZIE     ,
            pPrivAlerts:                   privileges[2].P_ALERTS              ,
            pPrivImpersonate:              privileges[2].P_IMPERSONATE         ,
            pPrivRestrictedAccess:         privileges[2].P_RESTRICTED_ACCESS   ,
            pPrivPolComm:                  privileges[2].P_POL_COMM            ,
            pPrivPolCommImport:            privileges[2].P_POL_COMM_IMPORT     ,
            pPrivPortafoglio:              privileges[2].P_PORTAFOGLIO         ,
            pPrivRoobeek:                  privileges[2].P_ROOBEEK             ,
            pPrivCbContracts:              privileges[2].P_CB_CONTRACTS        ,
            pPrivPipelineForecast:         privileges[2].P_PIPELINE_FORECAST   ,
            pPrivPipelineSnapshot:         privileges[2].P_PIPELINE_SNAPSHOT   ,
            pPrivTrackerAvailab    :       privileges[2].P_TRACKER_AVAILAB     ,
            pPrivTrackerAvailabPillar:     privileges[2].P_TRACKER_PILLAR      ,
            pPrivTrackerPillarDaily:       privileges[2].P_TRACKER_PILLAR_DAILY,
            pPrivOverbooking     :         privileges[2].P_OVERBOOKING         ,
            pPrivOverbookingBacino:        privileges[2].P_OVERBOOKING_BACINO  ,   
            pPrivProvvCommissions:         privileges[2].P_PROVV_COMMISSIONS   ,
            pPrivProvvStatements:          privileges[2].P_PROVV_STATEMENTS    ,
            pPrivAgenda:                   privileges[2].P_AGENDA              ,
            pPrivAnalysisTarget:           privileges[2].P_ANALYSIS_TARGET
           ,pPrivCRMChannels:              privileges[2].P_CRM_CHANNELS
           ,pPrivCRMDeliveries:            privileges[2].P_CRM_DELIVERIES
           ,pPrivCRMKubikAudiences:        privileges[2].P_CRM_KUBIK_AUDIENCES
           ,pPrivCRMMapping:               privileges[2].P_CRM_MAPPING
        };

        let responseStatus = 0;
        let responseRetVal = 0;

        //update privileges
        await FetchService.post('/users/user-mod-privs', paramsPrivs)
                .then((response) => {
                    responseStatus = response.status;
                    return response.json();
                }).then((data) => {
                    responseRetVal = data;
                })
                .catch((error) => {
                    console.log(error)
                });

        if (responseStatus != 200){
            NotificationWrapper.error(<IntlMessages id="error.global.generic"/>);
            return;
        }else if(responseRetVal <= 0){
            let errorMessage = "";
            switch (responseRetVal) {
                case 0:
                    errorMessage = <IntlMessages id="error.global.generic"/>;
                    break;
                case -1:
                    errorMessage = <IntlMessages id="error.global.generic"/>;
                    break;
                case -2:
                    errorMessage = <IntlMessages id="error.global.no-privileges"/>;
                    break;
                case -3:
                    errorMessage = <IntlMessages id="error-user-mod-priv-3"/>;
                    break;
                case -4:
                    errorMessage = <IntlMessages id="error-user-mod-priv-4"/>;
                    break;
                default:
                    errorMessage = <IntlMessages id="error.global.generic"/>;
                    break;
            }
            NotificationWrapper.error(errorMessage);
            return;
        }
        //update deny
        await FetchService.post("/users/user-mod-deny", paramsDeny)
            .then((response) => {
                responseStatus = response.status;
                return response.json();
            }).then((data) => {
                responseRetVal = data;
            }).catch((error) => {
                console.log(error)
            });

        if (responseStatus != 200){
            NotificationWrapper.error(<IntlMessages id="error.global.generic"/>);
            return;
        }else if(responseRetVal <= 0){
            let errorMessage = "";
            switch (responseRetVal) {
                case 0:
                case -1:
                    errorMessage = <IntlMessages id="error.global.generic"/>;
                    break;
                case -2:
                    errorMessage = <IntlMessages id="error.global.no-privileges"/>;
                    break;
                case -3:
                    errorMessage = <IntlMessages id="error-user-mod-priv-3"/>;
                    break;
                case -4:
                    errorMessage = <IntlMessages id="error-user-mod-priv-4"/>;
                    break;
                default:
                    errorMessage = <IntlMessages id="error.global.generic"/>;
                    break;
            }
            NotificationWrapper.error(errorMessage);
            return;
        }

        this.getPrivileges();
        this.props.setSaveFalse();
    }
    
    renderPrivilegeItem = ( oItem, nIndex, aoItems ) => {
        
        // per rilevare il cambio di "gruppo" di privilegi
        const isNewSection = ( nIndex === 0 ) || ( oItem.sSection !== aoItems[ nIndex - 1 ].sSection );
        
        return (
            <React.Fragment key={ nIndex }>
                { isNewSection && <div className="privileges-list title">{ oItem.sSection }</div> }
                <div className="privileges-list">
                    <div className="privileges-title">{ oItem.sPrivName }</div>
                    
                    {   // non so perchè siano invertiti i numeri VERIFICARE
                        [ 3, 2, 1, 0 ].map( ( nPrivIndex ) => {
                        
                            const isEditable  = [ 2, 0 ].includes( nPrivIndex );
                            const sPrivResult = this.state.privileges[ nPrivIndex ][ oItem.sPrivField ];
                            
                            return <div
                                key       ={ nPrivIndex }
                                className ={ `privileges-ico ${ nPrivIndex } ${ ( isEditable ? 'ico-link' : 'ico-no-link' ) }` }
                                onClick   ={ isEditable ? () => this.changeIcoPriv( oItem.sPrivField, nPrivIndex ) : undefined }
                            >
                                { ( nPrivIndex === 2 )
                                    ? this.icoDeny[ sPrivResult ]
                                    : this.icoPriv[ sPrivResult ]
                                }
                            </div>
                        })
                    }
                    
                </div>
            </React.Fragment>
        );
    };

    render() {
        
        if (this.state.loading) {
            return (
                <div id="privileges-list">
                </div>
            );
        }else {
            return (
                <div id="privileges-list">
                    <div className="HeadTitle">
                        <h1 className="">Privilege List</h1>
                        <div className="row p-2">
                            <div className="col-12">
                                <div className="privileges-list PrivilegeSticky">
                                    <div className="blue-title">Result</div>
                                    <div className="blue-title">Deny</div>
                                    <div className="blue-title">Roles</div>
                                    <div className="blue-title">Explicit</div>
                                </div>

                            </div>
                        </div>
                      </div>
                    
                    <div className="row p-2">
                        <div className="col-12">
                            { this.aoPrivilegesMap.map( this.renderPrivilegeItem ) }
                        </div>
                    </div>
                </div>
            
            );
        }
    }
}

export default connect(null,)(privilegeList);
