import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
    SIGNIN_USER,
    SIGNOUT_USER,
} from 'constants/ActionTypes';
import { showAuthMessage, userSignInSuccess, userSignOutSuccess } from 'actions/Auth';

import JwtAuthService from '../services/JwtAuthService';

const jwtAuthSrv = new JwtAuthService();

const signInUserWithEmailPasswordRequest = async (email, password, recaptchaValue) =>
    await  jwtAuthSrv.login (email, password, recaptchaValue)
        .then (authUser => authUser)
        .catch (error => error);

function* signInUserWithEmailPassword ({ payload }) {
    const { email, password, recaptchaValue } = payload;
    try {
        const signInUser = yield call (signInUserWithEmailPasswordRequest, email, password, recaptchaValue);

        if (signInUser.message) {
            yield put (showAuthMessage (signInUser.message));
        } else {

            localStorage.setItem("user_id", signInUser.token);
            localStorage.setItem("kUser", signInUser.kUser);
            localStorage.setItem("originalKUser", signInUser.kUser);
            localStorage.setItem("flagAdmin", signInUser.flagAdmin);
            localStorage.setItem("flagInternalUser", signInUser.flagInternalUser);
            localStorage.setItem("username", signInUser.username);
            localStorage.setItem("name", signInUser.name);
            localStorage.setItem("surname", signInUser.surname);
            localStorage.setItem("flagDoppioProfilo", signInUser.flagDoppioProfilo);
            localStorage.setItem("internalUserMode", 'N');
            localStorage.setItem('privileges', signInUser.privileges);
            localStorage.setItem('salesRepCod', signInUser.salesRepCod);
            localStorage.setItem('userMail', signInUser.userMail);
            localStorage.setItem('originalUserMail', signInUser.userMail);
            yield put (userSignInSuccess (signInUser));
        }
    } catch (error) {
        yield put (showAuthMessage (error));
    }
}

function* signOut () {
    try {
        localStorage.removeItem ('user_id');
        localStorage.removeItem("kUser");
        localStorage.removeItem("originalKUser");
        localStorage.removeItem("flagAdmin");
        localStorage.removeItem("flagInternalUser");
        localStorage.removeItem("username");
        localStorage.removeItem("name");
        localStorage.removeItem("surname");
        localStorage.removeItem("flagDoppioProfilo");
        localStorage.removeItem("internalUserMode");
        localStorage.removeItem("privileges");
        localStorage.removeItem("salesRepCod");
        localStorage.removeItem('userMail');
        localStorage.removeItem('originalUserMail');
        yield put (userSignOutSuccess ());

    } catch (error) {
        yield put (showAuthMessage (error));
    }
}

export function* signInUser () {
    yield takeEvery (SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser () {
    yield takeEvery (SIGNOUT_USER, signOut);
}

export default function* rootSaga () {
    yield all ([fork (signInUser),
        fork (signOutUser)]);
}