import React from 'react';
import {connect} from 'react-redux'
import {userSignOut} from 'actions/Auth';
import IntlMessages from 'util/IntlMessages';
import {Redirect} from "react-router-dom";
import Nprogress from "nprogress";
import FetchService from "../../services/FetchService";
import {history} from "../../store";

class UserInfo extends React.Component {

    constructor(props) {
        super(props);

        const role = localStorage.getItem("flagAdmin") == 'Y' ? "Administrator" : "User";
        const privileges =  JSON.parse(localStorage.getItem('privileges'));

        this.state = {
            kUser: localStorage.getItem("kUser"),
            originalKUser: localStorage.getItem("originalKUser"),
            privImpersonate: privileges.P_IMPERSONATE >= 'W' ? true : false,
            privRestrictedAccess: privileges.P_RESTRICTED_ACCESS >= 'W' ? true : false,
            username: localStorage.getItem("username"),
            name: localStorage.getItem("name"),
            surname: localStorage.getItem("surname"),
            role: role,
            linkChangePassword: false,
            linkImpersonateUser: false,
            linkRestrictedAccessMode: false,
        }
    }

    async handleChangePassword() {
        await this.setState({linkChangePassword: true});
        this.props.handleCloseQuickMenu();
    }

    async handleImpersonateUser() {
        await this.setState({linkImpersonateUser: true});
        this.props.handleCloseQuickMenu();
    }

    async handleEndImpersonateUser() {
        Nprogress.start();
        this.props.handleCloseQuickMenu();

        const kUser = localStorage.getItem('originalKUser');
        const params = {
            kUser: kUser,
        };

        let response = null;
        try {
            response = await FetchService.post("/end-impersonate", params);
        } catch (error) {
            Nprogress.done();
            this.props.userSignOut();
            return;
        }

        const responseRetVal = await response.json();
        this.setUserInfo(responseRetVal);
        Nprogress.done();
        history.push('/app/dashboard');
    }

    async handleRestrictedAccessMode() {
        await this.setState({linkRestrictedAccessMode: true});
        this.props.handleCloseQuickMenu();
    }

    setUserInfo(res) {
        localStorage.setItem('user_id', res.token);
        localStorage.setItem('kUser', res.kUser);
        localStorage.setItem('flagAdmin', res.flagAdmin);
        localStorage.setItem('flagInternalUser', res.flagInternalUser);
        localStorage.setItem('username', res.username);
        localStorage.setItem('name', res.name);
        localStorage.setItem('surname', res.surname);
        localStorage.setItem("flagDoppioProfilo", res.flagDoppioProfilo);
        localStorage.setItem("internalUserMode", 'N');
        localStorage.setItem('privileges', res.privileges);
        localStorage.setItem('salesRepCod', res.salesRepCod);
        localStorage.setItem('userMail', res.userMail);
        return;
    }

    render() {
        const {
            kUser,
            originalKUser,
            privImpersonate,
            privRestrictedAccess,
            name,
            surname,
            role,
            linkChangePassword,
            linkImpersonateUser,
            linkRestrictedAccessMode,
        } = this.state;


        //links
        if (linkChangePassword === true) {
            return <Redirect to='/app/settings/profile/change-password' push={true} />
        }
        if (linkImpersonateUser === true) {
            return <Redirect to='/app/settings/profile/impersonate-user' push={true} />
        }
        if (linkRestrictedAccessMode === true) {
            return <Redirect to='/app/settings/profile/restricted-access-mode' push={true} />
        }

        return (
            <div>
                <div className="user-profile">
                    <img className="user-avatar border-0 size-60"
                         src="assets/images/user-placeholder.jpg"
                         alt="User"/>
                    <div className="user-detail ml-2">
                        <h4 className="user-name mb-0">{name} {surname}</h4>
                        <small>{role}</small>
                    </div>
                </div>

                {kUser == originalKUser && (
                    <a className="dropdown-item text-muted" href="javascript:void(0)" onClick={() => {this.handleChangePassword(event)}}>
                        <i className="fas fa-key" style={{marginRight:7}}></i>
                        Change Password
                    </a>
                )}

                {privImpersonate && kUser == originalKUser && (
                    <a className="dropdown-item text-muted" href="javascript:void(0)" onClick={() => {this.handleImpersonateUser(event)}}>
                        <i className="fas fa-mask" style={{marginRight:5}}></i>
                        Impersonate User
                    </a>
                )}

                {kUser != originalKUser && (
                    <a className="dropdown-item text-muted" href="javascript:void(0)" onClick={() => {this.handleEndImpersonateUser(event)}}>
                        <i className="fas fa-user" style={{marginRight:9}}></i>
                        End Impersonate
                    </a>
                )}

                {false && privRestrictedAccess && (
                    <a className="dropdown-item text-muted" href="javascript:void(0)" onClick={() => {this.handleRestrictedAccessMode(event)}}>
                        <i className="fas fa-lock" style={{marginRight:5}}></i>
                        Restricted Access Mode
                    </a>
                )}

                <a className="dropdown-item text-muted" href="javascript:void(0)" onClick={() => {
                    //console.log("Try to logout");
                    this.props.userSignOut()
                }}>
                    <i className="fas fa-power-off" data-block-hide="true" style={{marginRight:8}}></i>
                    <IntlMessages id="popup.logout"/>
                </a>

                </div>
        );
    }
}

export default connect(null, {userSignOut})(UserInfo);


