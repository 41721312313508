/* eslint-disable quotes */
import React from 'react';
import "./Header.css";
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {Dropdown, DropdownMenu, DropdownToggle} from 'reactstrap';
import {COLLAPSED_DRAWER, FIXED_DRAWER, HORIZONTAL_NAVIGATION, INSIDE_THE_HEADER} from 'constants/ActionTypes';
import SearchBox from 'components/SearchBox';
import {switchLanguage, toggleCollapsedNav} from 'actions/Setting';
import UserInfo from 'components/UserInfo';
import Menu from "components/Header/Menu";
import {MuiThemeProvider, createMuiTheme, withStyles} from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import { history } from 'store';
import { Link } from 'react-router-dom';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#2789d8',
        },
    },
    typography: {
        useNextVariants: true,
        fontSize: 11,
    }
});

const AntSwitch = withStyles(theme => ({
    root: {
        width: 28,
        height: 16,
        padding: 0,
        display: 'flex',
    },
    switchBase: {
        padding: 2,
        color: theme.palette.primary.main,
        '&$checked': {
            transform: 'translateX(12px)',
            color: theme.palette.primary.main,
            '& + $track': {
                opacity: 1,
                backgroundColor: theme.palette.common.white,
                borderColor: theme.palette.common.white,
            },
        },
    },
    thumb: {
        width: 12,
        height: 12,
        boxShadow: 'none',
    },
    track: {
        color: theme.palette.primary.main,
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: theme.palette.common.white,
        borderColor: theme.palette.common.white,
    },
    checked: {},
}))(Switch);

class Header extends React.Component {

    constructor() {
        super();

        const internalUserMode = localStorage.getItem("internalUserMode") == 'Y' ? true : false;
        const flagDoppioProfilo = localStorage.getItem("flagDoppioProfilo") == 'Y' ? true : false;
        const name = localStorage.getItem("name");
        const surname = localStorage.getItem("surname");
        const username = name.length > 1 ? `${name.substr(0, 1)}. ${surname}` : `${name.substr(0, 1)} ${surname}`;
        const restrictedMode = localStorage.getItem("restrictedMode") != undefined ? localStorage.getItem("restrictedMode") : 'N';

        this.state = {
            searchBox: false,
            searchText: '',
            mailNotification: false,
            langSwitcher: false,
            appNotification: false,
            userInfo: false,
            username: username,
            internalUserMode: internalUserMode,
            flagDoppioProfilo: flagDoppioProfilo,
            restrictedMode: restrictedMode,
        }
    }

    componentWillReceiveProps(props) {

        const internalUserMode = localStorage.getItem("internalUserMode") == 'Y' ? true : false;
        const flagDoppioProfilo = localStorage.getItem("flagDoppioProfilo") == 'Y' ? true : false;
        const name = localStorage.getItem("name");
        const surname = localStorage.getItem("surname");
        const username = name.length > 1 ? `${name.substr(0, 1)}. ${surname}` : `${name.substr(0, 1)} ${surname}`;
        const restrictedMode = localStorage.getItem("restrictedMode") != undefined ? localStorage.getItem("restrictedMode") : 'N';
        // TODO this.setState(
        this.state = {
            username: username,
            internalUserMode: internalUserMode,
            flagDoppioProfilo: flagDoppioProfilo,
            restrictedMode: restrictedMode,
        }
    }


    onAppNotificationSelect = () => {
        this.setState({
            appNotification: !this.state.appNotification
        })
    };
    onMailNotificationSelect = () => {
        this.setState({
            mailNotification: !this.state.mailNotification
        })
    };

    onUserInfoSelect = () => {
        this.setState({
            userInfo: !this.state.userInfo
        })
    };

    onLangSwitcherSelect = (event) => {
        this.setState({
            langSwitcher: !this.state.langSwitcher
        })
    };
    onSearchBoxSelect = () => {
        this.setState({
            searchBox: !this.state.searchBox
        })
    };
    handleRequestClose = () => {
        this.setState({langSwitcher: false, mailNotification: false, appNotification: false, searchBox: false});
    };
    onToggleCollapsedNav = (e) => {
        const val = !this.props.navCollapsed;
        this.props.toggleCollapsedNav(val);
    };

    updateSearchText(evt) {
        this.setState({
            searchText: evt.target.value,
        });
    }

    handleCloseQuickMenu(){
        this.setState({
            userInfo: false,
        });
    }

    async handleChangeInternalUserMode(event){
        await this.setState({ internalUserMode : !this.state.internalUserMode });
        const internalUserMode = this.state.internalUserMode ? 'Y' : 'N';
        localStorage.setItem('internalUserMode', internalUserMode);
        history.push(history.location.pathname);
      
    }

    getSectionTitle = () => {
        
        const oSectionMappings = {
            
             '/app/dashboard'                               : 'CRM Analyzer'                             
            
            ,'/app/agenda/meeting-calendar'                 : 'Agenda | Meeting Calendar'                
            ,'/app/agenda/meeting-reasons'                  : 'Agenda | Meeting Reasons'                 
            ,'/app/agenda/contacts'                         : 'Agenda | Contacts'                        
            ,'/app/agenda/roles'                            : 'Agenda | Roles'                           
            ,'/app/agenda/meeting-report'                   : 'Agenda | Meeting Report'                  
            
            ,'/app/pipeline/forecast/1'                     : 'Pipeline | Forecast (Linear)'             
            ,'/app/pipeline/forecast/2'                     : 'Pipeline | Forecast (Digital)'            
            ,'/app/pipeline/forecast/deleted'               : 'Pipeline | Deleted Forecast'              
            
            ,'/app/tracker/brandsolutions'                  : 'Tracker | Brand Solutions'                
            ,'/app/tracker/pillar'                          : 'Tracker | Pillar'                         
            
            ,'/app/overbooking/overbooking'                 : 'Overbooking'                              
            
            ,'/app/commissions/list'                        : 'Sales Commissions'                                    
            ,'/app/commissions/settings'                    : 'Commission management'                    
            ,'/app/commissions/portfolio-variations'        : 'Portfolio Variations'                     
            ,'/app/commissions/sales-commission-statements' : 'Sales Commission Statements'              
            ,'/app/commissions/sales-commission-summary'    : 'Sales Commission Summary'                 
            
            ,'/app/portfolio/portfolio-actual'              : 'Portfolio | Actual'                       
            
            ,'/app/options/advertisers'                     : 'Global Settings | Analysis Targets'       
            ,'/app/options/channels'                        : 'Global Settings | Channels'               
            ,'/app/options/deliveries'                      : 'Global Settings | Deliveries'             
            ,'/app/options/kubik-audiences'                 : 'Global Settings | Kubik Audiences'        
            ,'/app/options/mapping'                         : 'Global Settings | Mapping'                
            
            ,'/app/usersList'                               : 'Global Settings | Users | User List'      
            ,'/app/usersRoles'                              : 'Global Settings | Users | Roles'          
            ,'/app/UserLoginHistory'                        : 'Global Settings | Users | Login history'  
            
            ,'/app/options/proposals'                       : 'Proposals'                                
            
            ,'/app/alert'                                   : 'Alverman | Alert'                         
            ,'/app/mapping'                                 : 'Alverman | Mapping'                       
            ,'/app/settings/profile/impersonate-user'       : 'Impersonate user'                         
            ,'/app/settings/profile/change-password'        : 'Change password'                          
            
            ,'/app/users/account-details/'                  : (
                <React.Fragment>
                    Global Settings | Users | <Link to="/app/usersList" className='LinkHeaderName'>User List</Link> | User Management
                </React.Fragment>
            )
        };
        
        return oSectionMappings[ history.location.pathname ] || '';
    }

    render() {
        const {username, flagDoppioProfilo, internalUserMode, restrictedMode} = this.state;
        const {drawerType, locale, navigationStyle, horizontalNavPosition} = this.props;
        const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'd-flex d-xl-none' : drawerType.includes(COLLAPSED_DRAWER) ? 'd-flex' : 'd-none';

        return (
            <div className="app-main-header">
                <div className={restrictedMode == 'Y' ? "restricted" : ""}>
                    <div className="d-flex app-toolbar align-items-center">
                        {navigationStyle === HORIZONTAL_NAVIGATION  ?
                            <div className="app-logo-bl">
                                <div className="d-block d-md-none">
                                    <span className="jr-menu-icon"
                                          onClick={this.onToggleCollapsedNav}>
                                        <span className="menu-icon"/>
                                    </span>
                                </div>
                                <div className="app-logo pointer d-none d-md-block">
                                    <img className="d-none d-lg-block" alt='...' src='http://via.placeholder.com/105x36'/>
                                    <img className="d-block d-lg-none mr-3" alt='...'
                                         src='http://via.placeholder.com/32x32'/>
                                </div>
                            </div>
                            :
                            <span className={`jr-menu-icon pointer ${drawerStyle}`}
                                  onClick={this.onToggleCollapsedNav}>
                                <span className="menu-icon"/>
                            </span>
                        }

                        <div className="section-title">
                            { this.getSectionTitle() }
                        </div>

                        <SearchBox styleName="d-none d-lg-block"
                                   onChange={this.updateSearchText.bind(this)}
                                   value={this.state.searchText}/>

                        {restrictedMode == 'Y' ? <div className="restricted-label">RESTRICTED ACCESS</div> : ""}

                        {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === INSIDE_THE_HEADER) &&
                        <Menu/>}

                        <ul className="header-notifications list-inline ml-auto">
                            <li className="list-inline-item user-nav" style={{display: 'none', paddingRight: 0, paddingLeft: 0, fontSize: 14}}>
                                <div className={flagDoppioProfilo ? "switch-internal-user-mode" : "switch-internal-user-mode-hide"}>
                                    <MuiThemeProvider theme={theme}>
                                        <Grid component="label" container alignItems="center" spacing={1} style={{width: 120}}>
                                            <Grid item>Portfolio</Grid>
                                            <Grid item style={{padding: 0}}>
                                                <AntSwitch
                                                    checked={internalUserMode}
                                                    onChange={() => this.handleChangeInternalUserMode()}
                                                />
                                            </Grid>
                                            <Grid item>All</Grid>
                                        </Grid>
                                    </MuiThemeProvider>
                                </div>
                            </li>

                            <li className="list-inline-item user-nav" style={{paddingRight: 0, marginRight: 0}}>
                                <span style={{fontSize: 14}}>{username}</span>
                            </li>

                            <li className="list-inline-item user-nav">
                                <Dropdown
                                    className="quick-menu"
                                    isOpen={this.state.userInfo}
                                    toggle={this.onUserInfoSelect.bind(this)}>

                                    <DropdownToggle
                                        className="d-inline-block"
                                        tag="span"
                                        data-toggle="dropdown">
                                        <img
                                            alt='...'
                                            src='assets/images/user-placeholder.jpg'
                                            className="pointer user-avatar size-40"/>
                                    </DropdownToggle>

                                    <DropdownMenu right>
                                        <UserInfo handleCloseQuickMenu={this.handleCloseQuickMenu.bind(this)} />
                                    </DropdownMenu>
                                </Dropdown>

                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }

}

const mapStateToProps = ({settings}) => {
    const {drawerType, locale, navigationStyle, horizontalNavPosition} = settings;
    return {drawerType, locale, navigationStyle, horizontalNavPosition}
};

export default withRouter(connect(mapStateToProps, {toggleCollapsedNav, switchLanguage})(Header));
