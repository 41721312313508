import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import asyncComponent from '../../../util/asyncComponent';

const Commissions = ({match}) => (
    <div className="app-wrapper">
        <Switch>
            <Redirect exact from={`${match.url}/`} to={`${match.url}/list`}/>
            <Route path={`${match.url}/list/`} component={asyncComponent(() => import('./routes/list'))}/>
            <Route path={`${match.url}/settings/`} component={asyncComponent(() => import('./routes/settings'))}/>
            <Route path={`${match.url}/portfolio-variations/`} component={asyncComponent(() => import('./routes/portfolio-variations'))}/>
            <Route path={`${match.url}/sales-commission-statements/`} component={asyncComponent(() => import('./routes/sales-commission-statements'))}/>
            <Route path={`${match.url}/sales-commission-summary/`} component={asyncComponent(() => import('./routes/sales-commission-summary'))}/>            
            <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>
        </Switch>
    </div>
);

export default Commissions;