import {NotificationManager} from 'react-notifications';

export default class NotificationWrapper {

    static error(errorMessage, titleMessage = '') {
        NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
        NotificationManager.error(errorMessage, titleMessage, process.env.TIMEOUT_MSG_ERROR_GEN);
    }

    static warning(errorMessage, titleMessage = '') {
        NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
        NotificationManager.warning(errorMessage, titleMessage, process.env.TIMEOUT_MSG_ERROR_GEN);
    }

    static success(errorMessage, titleMessage = ''){
        NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
        NotificationManager.success(errorMessage, titleMessage, process.env.TIMEOUT_MSG_SUCCESS);
    }

    static info(errorMessage, titleMessage = ''){
        NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
        NotificationManager.info(errorMessage, titleMessage, process.env.TIMEOUT_MSG_SUCCESS);
    }
}


