import React from 'react';
import './index.css';
import Backdrop from '@mui/material/Backdrop';

const Spinner = ({visible}) => {
    if (visible) {
        return <Backdrop 
                    className="spinner-bkg"
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }}
                    open={true}
               >
                <div id="spinner-wrap"></div>
               </Backdrop>;
    } else {
        return <div></div>;
    }
};
export default Spinner;
