import React from 'react';
import {withRouter} from 'react-router-dom'
import {MINI_DRAWER} from "constants/ActionTypes";

const SidenavLogo = (props) => {

    const showMini = props.drawerType.includes(MINI_DRAWER);

    const goToDashboard = () => {
        props.history.push('/app/dashboard')
    }

    return (
        <div className="sidebar-header d-flex align-items-center cursor-pointer" onClick={goToDashboard}>
            <div className="normal-logo"><img src="assets/images/marchio_crm-analyzer.svg" /></div>
            <div className="normal-logo-ma left">crm</div>
            <div className="normal-logo-ma">analyzer</div>
        </div>
    );
};

export default withRouter(SidenavLogo);
