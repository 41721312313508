
import React, { useState } from 'react';
import {
    DataGridPro,
    LicenseInfo,
    GridOverlay
  } from '@mui/x-data-grid-pro';
  import Tabs from '@mui/material/Tabs';
  import Tab from '@mui/material/Tab';
  import Typography from '@mui/material/Typography';
  import Box from '@mui/material/Box';
  import Button from '@mui/material/Button';
  import ButtonGroup from '@mui/material/ButtonGroup';

  import './alert.css'

    export default function Alert() {

        const rows = [
            { id: 1, col1: 'x', col2: 'x' },
            { id: 2, col1: 'x', col2: 'x x' },
            { id: 3, col1: 'x', col2: 'x x' },
          ];
      
          const columns = [
            { field: 'Code',            headerName: "Code",            width: 150, editable: false },
            { field: 'Advertiser',      headerName: "Advertiser",      width: 300, editable: false },
            { field: 'Year',            headerName: "Year",            width: 250, editable: false },
            { field: 'Investment /000', headerName: "Investment /000", width: 250, editable: false },
          ];


        const [isHidden, setIsHidden] = useState(false);
        const [tabValue, setTabValue] = useState(0);

        const handleButtonClick = () => {
            setIsHidden(true);
        };
        const handleButtonClickFalse = () => {
            setIsHidden(false);
        };

        const buttons = document.querySelectorAll('.button');

        buttons.forEach(button => {
            button.addEventListener('click', () => {
                buttons.forEach(btn => {
                    btn.classList.remove('selected');
                });

                button.classList.add('selected');
            });
        });


        function a11yProps(index) {
            return {
              id: `simple-tab-${index}`,
              'aria-controls': `simple-tabpanel-${index}`,
            };
          }
        const handleChangeTab = (event, newValue) => {
            setTabValue(newValue);
          };

      return (
        <div className='MappingAlverman'>

            <div id="dataGrid" className={`usersDataGrid MuiDataGrid-root `} style={{ width: '100%' }}>

                <div className="header">
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={tabValue} onChange={handleChangeTab} aria-label="basic tabs example">
                            <Tab label="Unmapped Investments" {...a11yProps(0)} />
                            <Tab label="Multiple Agents" {...a11yProps(2)} />
                        </Tabs>
                    </Box>


                    <ButtonGroup variant="contained" aria-label="outlined primary button group" className='Buttons'>
                        <Button className="button"> Discovery</Button>
                        <Button className="button"> Nielsen</Button>
                    </ButtonGroup>
                </div>


                <DataGridPro
                    rows={rows}
                    columns={columns}
                    className={`${isHidden ? 'Hide' : ''}`}
                />
            </div>

        </div>
      );
    }



