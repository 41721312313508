import React, { useState } from 'react';
import { Button, Modal, TextField, Switch,FormControlLabel} from "@material-ui/core";




const ModalAutocomplete = ({isOpen, onClose}) => {
      


  return (
    <Modal open={isOpen} onClose={onClose}>
    <div className="modal-container">
      <div>
         <h1>Mapping</h1>
 
      
      </div>
    </div>
    </Modal>
  );
};

export default ModalAutocomplete;
