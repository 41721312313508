import React, { useState, useEffect } from 'react';
import { Link, redirect } from "react-router-dom";
import FetchService from '../../../services/FetchService';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@mui/material/IconButton';
import IntlMessages from 'util/IntlMessages';
import { Tooltip } from '@material-ui/core';
import Nprogress from "nprogress";
import { Button, Modal, Switch, FormControlLabel } from "@material-ui/core";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import ModalNewUser from './ModalNewUser';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import NotificationWrapper from "util/NotificationWrapper";
import {
  DataGridPro,
  LicenseInfo,
  GridOverlay
} from '@mui/x-data-grid-pro';
import './users.css'


const useStyles = makeStyles((theme) => ({
  assetRoot: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: '2px 0',
    margin: 0,
  },

  gridRoot: {
    border: 0,
    '& .MuiDataGrid-cell--editable': {
      //backgroundColor: '#ecf3f0 !important'
    },
    fontSize: "0.830rem !important"
  },

  noRowsRoot: {
    flexDirection: 'column',
    '& .ant-empty-img-1': {
      fill: theme.palette.type === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
      fill: theme.palette.type === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
      fill: theme.palette.type === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
      fill: theme.palette.type === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
      fillOpacity: theme.palette.type === 'light' ? '0.8' : '0.08',
      fill: theme.palette.type === 'light' ? '#f5f5f5' : '#fff',
    },
  },
  margin: {
    margin: theme.spacing(0.5),
  },
}));

function CustomNoRowsOverlay() {
  const classes = useStyles();

  return (
    <GridOverlay className={classes.noRowsRoot}>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        {/* SVG paths */}
      </svg>
      <div className={classes.label}>No Rows</div>
    </GridOverlay>
  );
}

function Index() {
  const [users, setUsers] = useState([]);
  const [paramsDel, setParamsDel] = useState([]);
  const [paramsDelSurname, setParamsDelSurname] = useState([]);
  const [enabledUsers, setEnabledUsers] = useState(true);
  const privileges = JSON.parse(localStorage.getItem('privileges'));
  const getRowId = (row) => row.KUSER;
  const [tableCols, setTableCols] = useState([]);

  const columns = [
    {
      field: ' ', headerClassName: 'header-cell CENTERED_CELL', cellClassName: 'CENTERED_FLAG', headerName: " ", width: 45, resizable: false, sortable: false, editable: false,
      renderCell: (params) =>
      (<div>
        <Link to={`users/account-details/${params.id}`} >
          <IconButton title='Edit User'>
            <EditIcon className="ACTION_CELL EditButton" />
          </IconButton>
        </Link>
      </div>)
    },
    { field: 'KUSER', headerName: "User ID ", type: 'text', width: 70, editable: false, align: 'right', },
    { field: 'USERNAME', headerClassName: 'header-cell', headerName: "Username", type: 'text', width: 110, editable: false },
    { field: 'COGNOME', headerClassName: 'header-cell', headerName: "Surname", type: 'text', width: 125, editable: false },
    { field: 'NOME', headerClassName: 'header-cell', headerName: "Name", type: 'text', width: 125, editable: false },
    { field: 'EMAIL_ADDRESS', headerClassName: 'header-cell', headerName: "E-Mail", type: 'mail', width: 185, editable: false },
    { field: 'AGENTE_COD', headerClassName: 'header-cell', headerName: "Sales Rep. list", type: 'text', width: 180, editable: false },
    { field: 'FLAG_DOPPIO_PROFILO', headerClassName: 'header-cell CENTERED_CELL', cellClassName: 'CENTERED_FLAG', headerName: "Dual", type: 'text', sortable: false, width: 70, editable: false, renderCell: (params) => (params.value === 'N' ? '' : <i class="fa-solid fa-check"></i>) },
    { field: 'FLAG_ADMIN', headerClassName: 'header-cell CENTERED_CELL', cellClassName: 'CENTERED_FLAG', headerName: "Admin", type: 'text', width: 70, sortable: false, editable: false, renderCell: (params) => (params.value === 'N' ? '' : <i class="fa-solid fa-check"></i>) },
    { field: 'FLAG_ATTIVO', headerClassName: 'header-cell CENTERED_CELL', cellClassName: 'CENTERED_FLAG', headerName: "Enabled", type: 'text', width: 90, resizable: false, editable: false, renderCell: (params) => <i title={params.value === 'N' ? 'Enable user' : 'Disable user'} onClick={() => toggleFlagAttivo({ ...params })} className={`${params.value === 'N' ? 'fa fa-toggle-off redtoggle' : 'fa fa-toggle-on greentoggle'}`}></i> }
  ];

  useEffect(() => {
    LicenseInfo.setLicenseKey(
      'eb7eda5c1b4f6e8e4d0eb35b9fb0df81T1JERVI6MjYwMjcsRVhQSVJZPTE2NTUzODE4MzMwMDAsS0VZVkVSU0lPTj0x'
    );

    getUserList();
  }, [enabledUsers])

  useEffect(() => {
    if (enabledUsers === true) {
      const filteredUsers = users.filter(user => user.FLAG_ATTIVO !== 'N');
      setUsers(filteredUsers);
    }
  }, [enabledUsers]);

  const modUserRoles = async (cellParams) => {

    const formatRoleCodeListOnAdd = (cellParams) => {
      let arrRoles = cellParams.row.ROLE_LIST.split(',');
      arrRoles.push(cellParams.colDef.roleCode.toString());
      let newRoleList = arrRoles.join(',');
      if (newRoleList.startsWith(',')) {
        newRoleList = newRoleList.substring(1);
      }
      return newRoleList;
    }

    let params = {
      userId: cellParams.row.KUSER,
      username: cellParams.row.USERNAME,
      nome: cellParams.row.NOME,
      cognome: cellParams.row.COGNOME,
      eMailAddress: cellParams.row.EMAIL_ADDRESS,
      pAgenteCod: cellParams.row.AGENTE_COD,
      flagDoppioProfilo: cellParams.row.FLAG_DOPPIO_PROFILO,
      flagAdmin: cellParams.row.FLAG_ADMIN,
      pRoleCodesList: cellParams.row.ROLE_LIST.split(',').some(code => code === (cellParams.value ? cellParams.value.toString() : null))
        ? cellParams.row.ROLE_LIST.split(',').filter(code => code !== cellParams.value.toString()).join(',')
        : formatRoleCodeListOnAdd(cellParams)
    }
    try {
      await FetchService.post("/users/user-mod", params);
      getUserList();
    } catch (e) {
      NotificationWrapper.error(e.description);
    }

  }

  const getUserList = async () => {
    const flagSoloAttivi = enabledUsers ? 'Y' : 'N';

    const params = {
      flagSoloAttivi: flagSoloAttivi,
    };

    try {
      let response = await FetchService.get("/users/user-list", params);
      let roleCols = response.roles.map(ruolo => {
        return {
          field: ruolo.ROLE_DESC,
          roleCode: ruolo.ROLE_COD,
          headerName: ruolo.ROLE_DESC,
          headerClassName: 'header-cell CENTERED_CELL',
          cellClassName: 'CENTERED_FLAG',
          width: 140,
          resizable: false,
          editable: false,
          renderCell: (params) =>
          (<div>
            {!params.value
              ?
              <CheckBoxOutlineBlankIcon className='pointer' onClick={() => modUserRoles(params)} />
              :
              <CheckBoxIcon className='pointer' onClick={() => modUserRoles(params)} />}
          </div>)
        }
      }
      )
      setTableCols([...columns,
      ...roleCols,
      {
        field: '', headerClassName: 'header-cell CENTERED_CELL', cellClassName: 'CENTERED_FLAG', headerName: " ", width: 45, resizable: false, sortable: false, editable: false,
        renderCell: (params) =>
        (<div>
          <IconButton title='Delete User' onClick={() => openModalDel(params)} className='ACTION_CELL DeleteButton' >
            <DeleteIcon />
          </IconButton>

        </div>)
      }]
      )
      setUsers(response.users);
    } catch (error) {
      console.log('error userlist', error);
    }
  };


  const handleChangeEnabledUsers = (event) => {
    setEnabledUsers(!enabledUsers);
  }


  const enableUser = async (userId) => {
    const params = {
      userId,
    }
    let response = null;
    try {
      response = await FetchService.post("/users/userEnable", params);
    } catch (error) {
      console.log(error)
    }
    const responseRetVal = await response.json();

    if (responseRetVal <= 0) {
      let errorMessage = "";
      switch (responseRetVal) {
        case 0:
          errorMessage = <IntlMessages id="error.global.generic" />;
          break;
        default:
          errorMessage = <IntlMessages id="error.global.generic" />;
          break;
      }

      NotificationWrapper.error(errorMessage);
      return;
    }

    NotificationWrapper.success(<IntlMessages id="message.global.success" />);
    getUserList();
  }
  const disableUser = async (userId) => {
    const params = {
      userId,
    }
    let response = null;
    try {
      response = await FetchService.post("/users/user-disable", params);
    } catch (error) {
      console.log(error)
    }
    const responseRetVal = await response.json();

    if (responseRetVal <= 0) {
      let errorMessage = "";
      switch (responseRetVal) {
        case 0:
          errorMessage = <IntlMessages id="error.global.generic" />;
          break;
        default:
          errorMessage = <IntlMessages id="error.global.generic" />;
          break;
      }

      NotificationWrapper.error(errorMessage);
      return;
    }

    NotificationWrapper.success(<IntlMessages id="message.global.success" />);
    getUserList();
  }


  const toggleFlagAttivo = async (params) => {
    const { row, id } = params;
    setUsers((prevUsers) => {
      const updatedUsers = prevUsers.map((user) => {
        if (user.KUSER === id) {
          return {
            ...user,
            FLAG_ATTIVO: user.FLAG_ATTIVO === 'N' ? 'Y' : 'N',
          };
        }
        return user;
      });

      setUsers(updatedUsers);

      if (row.FLAG_ATTIVO === 'Y') {
        disableUser(id);
      } else {
        enableUser(id);
      }

      return updatedUsers;
    });
  };


  const deleteButton = async (id) => {
    let response = null;

    const params = {
      userId: id,
    }
    try {
      response = await FetchService.post("/users/user-del", params);
    } catch (error) {
      Nprogress.done();
      //console.log(error, 'DELETE_USER')
      return;
    }

    const responseRetVal = await response.json();

    if (responseRetVal <= 0) {
      let errorMessage = "";
      switch (responseRetVal) {
        case 0:
        case -1:
          errorMessage = <IntlMessages id="error.global.generic" />;
          break;
        case -2:
          errorMessage = <IntlMessages id="error.global.no-privileges" />;
          break;
        case -3:
          errorMessage = <IntlMessages id="error-user-del-3" />;
          break;
        case -4:
          errorMessage = <IntlMessages id="error-user-del-4" />;
          break;
      }

      NotificationWrapper.error(errorMessage);
      Nprogress.done();
      return;
    } else if (responseRetVal > 0) {
      NotificationWrapper.success('User deleted');
      getUserList();
    }
  }
  // MODAL  MODAL MODAL

  const [openModal, setOpenModal] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const openModalDel = (params) => {
    setOpenModalDelete(true);
    setParamsDel(params.row);
    setParamsDelSurname(params.row.COGNOME);
  };

  const closeModalDel = () => {
    setOpenModalDelete(false);

  };

  return (
    <div className='UserList'>


      <span className="button-left">
        <FormControlLabel
          control={
            <Switch
              name="enabledUsers"
              checked={enabledUsers}
              onChange={handleChangeEnabledUsers}
              color="primary"
            />
          }
          label="Enabled Users only"
        />
      </span>

      {privileges.FLAG_ADMIN !== 'Y' ? null : (
        <DataGridPro
          components={{
            NoRowsOverlay: CustomNoRowsOverlay,

          }}
          rows={users}
          columns={tableCols}
          pinnedColumns={{ left: [' ', 'KUSER', 'USERNAME'], right: [] }}
          rowHeight={45}
          hideFooter={true}
          disableColumnMenu={true}
          disableColumnReorder={true}
          getRowId={getRowId}
          className='usersDataGrid'
          // onRowClick={(params) => window.location.href = `#/app/users/account-details/${params.id}`}
          getRowClassName={(params) =>
            params.row.FLAG_ATTIVO === 'N' ? 'redRow' : ''
          }
        />
      )}

      <span className="button">
        <Tooltip title="New User">
          <Fab className='bg-primary' size="small" >
            <AddIcon onClick={handleOpenModal} />
          </Fab>
        </Tooltip>
      </span>

      <ModalNewUser
        isOpen={openModal}
        onClose={handleCloseModal}
        callUser={getUserList}
      />

      <Modal open={openModalDelete} onClose={closeModalDel} >
        <div className="modal-container">
          <div className="modal-content modal-del">
            <h2>Delete User</h2>
            <div>Are you sure you want to delete user {paramsDel.KUSER}  ({paramsDel.NOME} {paramsDel.COGNOME})?</div>
            <div className='d-flex'>
              <Button onClick={() => { deleteButton(paramsDel.id); closeModalDel(paramsDel); }} variant="contained" color="secondary" className="mr-2 shadow mt-3">
                Delete
              </Button>
              <Button onClick={closeModalDel} variant="contained" className='shadow mt-3' >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Index;
