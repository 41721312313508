import React from "react";
import "./UserActivity.css";
import FetchService from 'services/FetchService';
import Paper from '@material-ui/core/Paper';
import {
    PagingState,
    IntegratedPaging,
    FilteringState,
    IntegratedFiltering,
    SortingState,
    IntegratedSorting,
    DataTypeProvider,
} from '@devexpress/dx-react-grid';
import {
    Grid,   
    TableHeaderRow,
    PagingPanel, Table, TableFilterRow, TableColumnResizing,
} from '@devexpress/dx-react-grid-material-ui';

import CommonUtilities from "util/CommonUtilities";
import {connect} from "react-redux";

import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import Nprogress from "nprogress";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { Checkbox } from "@material-ui/core";




const compareNumber = (a, b) => {
    const inta = parseInt(a);
    const intb = parseInt(b);

    if (inta === intb) {
        return 0;
    }

    return (inta < intb) ? -1 : 1;
};


const StandardFormatter = ({ row, value }) => value;

const StandardTypeProvider = props => (
    <DataTypeProvider
        formatterComponent={StandardFormatter}
        {...props}
    />
);

const DateFormatter = ({ value }) =>  <div>{CommonUtilities.getFormatDateTime(value)}</div>;

const DateTypeProvider = props => (
    <DataTypeProvider
        formatterComponent={DateFormatter}
        {...props}
    />
);

const FilterCell = (props) => {

    return <TableFilterRow.Cell {...props} />;
};

class UserActivity extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            applicazioneCod: 1,
            loading: true,
            rows: [],
            columns: [
                { name: 'LOGIN_DATE', title: 'Login Date' },
                { name: 'KUSER', title: 'User ID' },
                { name: 'USERNAME', title: 'Username' },
                { name: 'COGNOME', title: 'Surname' },
                { name: 'NOME', title: 'Name' },
                { name: 'REASON', title: 'Reason' },
            ],
            filteringStateColumnExtensions: [
                { columnName: 'LOGIN_DATE', filteringEnabled: false },
                { columnName: 'KUSER', filteringEnabled: true },
                { columnName: 'USERNAME', filteringEnabled: true },
                { columnName: 'NOME', filteringEnabled: true },
                { columnName: 'COGNOME', filteringEnabled: true },
                { columnName: 'REASON', filteringEnabled: true },
            ],
            tableColumnExtensions: [
                { columnName: 'LOGIN_DATE', align: 'center', width: 200},
                { columnName: 'KUSER', align: 'right', width: 100 },
                { columnName: 'USERNAME', align: 'left', width: 140},
                { columnName: 'NOME', align: 'left', width: 140 },
                { columnName: 'COGNOME', align: 'left', width: 140 },
                { columnName: 'REASON', align: 'left' },
            ],
            kUserColumns: [],
            standardColumns: ['KUSER', 'USERNAME', 'NOME', 'COGNOME', 'REASON'],
            dateColumns: ['LOGIN_DATE'],
            integratedSortingColumnExtensions: [
                { columnName: 'KUSER', compare: compareNumber },
                { columnName: 'LOGIN_DATE', compare: compareNumber },
            ],
            startDate: null,
            fromDate: "",
            endDate: null,
            toDate: "",
        };
    }

    async componentDidMount() {
        const endDate = new Date();
        const toDate = CommonUtilities.getStringFromDate(endDate);

        let startDate = new Date();
        startDate.setDate(startDate.getDate() - 6);

        const fromDate = CommonUtilities.getStringFromDate(startDate);

        await this.setState({
            startDate: startDate,
            fromDate: fromDate,
            endDate: endDate,
            toDate: toDate,
        });

        this.loadGrid();
    }

    loadGrid(){
        Nprogress.start();

        const params = {
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            applicazioneCod: this.state.applicazioneCod,
        };

        FetchService.get("/users/user-login-history", params)
            .then((data) => {
                this.setState({
                    rows: data,
                    loading: false
                });
                Nprogress.done();
            })
            .catch((error) => {
                // this.props.userSignOut()
                console.log(error)
            });
    }

    async handleDayChangeStart(selectedDay) {
        if(selectedDay != undefined) {
            const fromDate = CommonUtilities.getStringFromDate(selectedDay);

            await this.setState({
                startDate: selectedDay,
                fromDate: fromDate
            });
        }else{
            await this.setState({
                startDate: selectedDay,
                fromDate: ''
            });
        }

        this.loadGrid();
    }

    async handleDayChangeEnd(selectedDay) {
        if(selectedDay != undefined) {
            const toDate = CommonUtilities.getStringFromDate(selectedDay);
            await this.setState({
                endDate: selectedDay,
                toDate: toDate
            });
        }else{
            await this.setState({
                endDate: selectedDay,
                toDate: ''
            });
        }

        this.loadGrid();
    }

    async handleChange(event){
        await this.setState({ [event.target.name] : event.target.value});
        this.loadGrid();
    }

    render() {
        const {
            loading,
            rows,
            columns,
            standardColumns,
            dateColumns,
            filteringStateColumnExtensions,
            integratedSortingColumnExtensions,
            tableColumnExtensions,
            startDate,
            endDate,
            applicazioneCod,
        } = this.state;

        if(loading){
            return(
                <div class="user-login-history"></div>
            );
        }else {
            return (
                <div class="user-login-history">
                        <div className="row date-filter">
                            <div className="col-lg-4 col-md-8 col-sm-8 col-8" style={{maxWidth: 250}}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        clearable
                                        label="From Date"
                                        format="dd/MM/yyyy"
                                        value={startDate}
                                        onChange={this.handleDayChangeStart.bind(this)}

                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                            <div className="col-lg-4 col-md-8 col-sm-8 col-8" style={{maxWidth: 250}}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        clearable
                                        label="To Date"
                                        format="dd/MM/yyyy"
                                        value={endDate}
                                        onChange={this.handleDayChangeEnd.bind(this)}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>

                            <div className="col-lg-4 col-md-8 col-sm-8 col-8" style={{maxWidth: 250}}>
                                <FormControl>
                                    <InputLabel htmlFor="applicazioneCod">Application</InputLabel>
                                    <Select
                                        value={applicazioneCod}
                                        onChange={this.handleChange.bind(this)}
                                        inputProps={{
                                            name: 'applicazioneCod',
                                            id: 'applicazioneCod',
                                        }}
                                        style={{minWidth: 200}}
                                    >
                                        <MenuItem value="1">Alverman </MenuItem>
                                        <MenuItem value="2">DasMarianna</MenuItem>
                                        <MenuItem value="3">Roobeek</MenuItem>
                                        <MenuItem value="4">CRM Analyzer</MenuItem>
                                    </Select>

                                    
                                </FormControl>
                            </div>
                        </div>
                    <Paper>
                        <Grid
                            rows={rows}
                            columns={columns}
                        >

                            <StandardTypeProvider
                                for={standardColumns}
                                formatterComponent={StandardFormatter}
                            />

                            <DateTypeProvider
                                for={dateColumns}
                                formatterComponent={DateFormatter}
                            />

                            <PagingState
                                defaultCurrentPage={0}
                                pageSize={50}
                            />

                            <FilteringState
                                columnExtensions={filteringStateColumnExtensions}
                            />

                            <IntegratedFiltering/>
                            <SortingState
                                defaultSorting={[{columnName: 'LOGIN_DATE', direction: 'desc'}]}
                            />
                            <IntegratedSorting
                                columnExtensions={integratedSortingColumnExtensions}
                            />

                            <IntegratedPaging/>

                            <Table columnExtensions={tableColumnExtensions} rowComponent={({row, ...restProps }) => (
                                <Table.Row
                                    {...restProps}
                                />
                            )} />

                            <TableHeaderRow showSortingControls/>

                            <TableFilterRow
                                cellComponent={FilterCell}
                            />

                            <PagingPanel />

                        </Grid>
                    </Paper>
                </div>
            );
        }
    }
}

export default connect(null)(UserActivity);
