import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import asyncComponent from '../../../util/asyncComponent';
import Error404 from '../extraPages/routes/404';

const Options = ({match}) => {
    
    let privileges = {};
    try {
        privileges = JSON.parse(localStorage.getItem('privileges'));
    } catch (e) {
        console.error(e);
    }
    
    const
         { FLAG_ADMIN, P_ANALYSIS_TARGET, P_CRM_CHANNELS, P_CRM_DELIVERIES, P_CRM_KUBIK_AUDIENCES, P_CRM_MAPPING } = privileges
        ,isAdmin           = FLAG_ADMIN === 'Y'
        ,hasAdvertisers    = P_ANALYSIS_TARGET      > 'N'
        ,hasChannels       = P_CRM_CHANNELS         > 'N'
        ,hasDeliveries     = P_CRM_DELIVERIES       > 'N'
        ,hasKubikAudiences = P_CRM_KUBIK_AUDIENCES  > 'N'
        ,hasMapping        = P_CRM_MAPPING          > 'N'
        ,hasOptionsAccess  = isAdmin || hasAdvertisers || hasChannels || hasDeliveries || hasKubikAudiences || hasMapping
    ;
    
    if ( !hasOptionsAccess ) {
        return <Error404/>;
    }
    
    return (
        <div className="app-wrapper">
            <Switch>
                
                <Redirect exact from={`${match.url}/`} to={`${match.url}/advertisers`} />
                
                { hasAdvertisers    && <Route path={`${match.url}/advertisers/`     } component={asyncComponent(() => import('./routes/advertisers'    ))} /> }
                { hasChannels       && <Route path={`${match.url}/channels/`        } component={asyncComponent(() => import('./routes/channels'       ))} /> }
                { hasDeliveries     && <Route path={`${match.url}/deliveries/`      } component={asyncComponent(() => import('./routes/deliveries'     ))} /> }
                { hasKubikAudiences && <Route path={`${match.url}/kubik-audiences/` } component={asyncComponent(() => import('./routes/kubikAudiences' ))} /> }
                { hasMapping        && <Route path={`${match.url}/mapping/`         } component={asyncComponent(() => import('./routes/mapping'        ))} /> }
                
                { isAdmin && ( // tutte le rotte solo per admin     
                    <React.Fragment>
                                       <Route path={`${match.url}/proposals/`       } component={asyncComponent(() => import('./routes/proposals'      ))} />
                    </React.Fragment>
                ) }
                { /* rotta 404 */ }
                                       <Route                                         component={asyncComponent(() => import( 'app/routes/extraPages/routes/404' ))}/>
                
            </Switch>
        </div>
    );
}

export default Options;