import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import asyncComponent from '../../../util/asyncComponent';
import Error404 from '../extraPages/routes/404';

const Agenda = ({match}) => {
    const privileges =  JSON.parse(localStorage.getItem('privileges'));
    const isAdmin = !!(privileges.FLAG_ADMIN === 'Y');
    const userHasPrivAgenda = !!(privileges.P_AGENDA >= 'R');

    return(
        <div className="app-wrapper">
            <Switch>
                {(isAdmin || userHasPrivAgenda)&&(<Route path={`${match.url}/meeting-calendar`} component={asyncComponent(() => import('./routes/meetingCalendar'))}/>)}
                {(isAdmin || userHasPrivAgenda)&&(<Route path={`${match.url}/meeting-report/:reportId`} component={asyncComponent(() => import('./routes/meetingReport'))}/>)}
                {(isAdmin || userHasPrivAgenda)&&(<Route path={`${match.url}/contacts`} component={asyncComponent(() => import('./routes/contacts'))}/>)}
                {isAdmin&&(<Route path={`${match.url}/meeting-reasons`} component={asyncComponent(() => import('./routes/meetingReasons'))}/>)}
                {isAdmin&&(<Route path={`${match.url}/roles`} component={asyncComponent(() => import('./routes/roles'))}/>)}
                <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>
            </Switch>
        </div>
    );
}

export default Agenda;